import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { MaybeLink, Button } from '~/components/elements/Link';
import { getBgClass } from '~/components/layouts/Section';
import { MediaImage } from '~/components/elements/Media';
import { Person, Cart, Phone, Clock, ArrowUp, ChevronRight, Search } from '~/components/elements/Icon';
import CartIcon from '~/components/shop/cart/CartIcon';
import useDocumentScrollThrottled from '~/hooks/useDocumentScrollThrottled';
import { Link, navigate } from 'gatsby';
import queryString from 'query-string';

export default function Header({ page, data, headerColor, headerType }) {
  const { optionsHeader } = data;

  const headerClasses = [
    'z-40 sticky top-0 hover:bg-white hover:text-navy transition-all duration-500 ease-in-out hover:shadow-sm',
  ];
  const backgroundColour = getBgClass(headerColor);
  headerClasses.push(backgroundColour);

  const [navIsOpen, setNavIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const MINIMUM_SCROLL = 0;

  useDocumentScrollThrottled((callbackData) => {
    if (typeof window === 'undefined') return;

    const { previousScrollTop, currentScrollTop } = callbackData;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setIsScrolled(isMinimumScrolled);
  });

  return (
    <>
      {headerType === 'form' ? (
        <HeaderFormFlow backgroundColour={backgroundColour} />
      ) : (
        <>
          <PromoBar {...optionsHeader.promoBar} />
          <header
            className={`${headerClasses.join(' ')} ${isScrolled && '!bg-white text-navy shadow-sm'}`}
            role="banner"
          >
            <HeaderInner
              {...optionsHeader.header}
              setNavIsOpen={setNavIsOpen}
              navIsOpen={navIsOpen}
              isScrolled={isScrolled}
            />
          </header>
        </>
      )}
    </>
  );
}

export const PromoBar = ({ contact, promotion }) => {
  return (
    <div className="hidden md:block bg-yellow text-navy px-5 lg:px-10">
      <div className="container container-full-proper ">
        <div className="flex  py-4 items-center space-y-4 xl:space-y-0 xl:justify-between flex-col xl:flex-row">
          {promotion && (
            <div className="flex flex-col md:flex-row text-center md:text-left space-y-2 md:space-y-0 items-center space-x-2 text-[14px] font-semibold">
              <MediaImage image={promotion.image} className="w-[60px] h-auto" />
              <div>{promotion.content}</div>
              <MaybeLink link={promotion.link} className="underline">
                {promotion.link.title}
              </MaybeLink>
            </div>
          )}
          {contact && (
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 items-center md:space-x-6 text-[14px]">
              <div className="flex items-center space-x-1 ">
                <Phone />
                <div
                  dangerouslySetInnerHTML={{ __html: contact.call }}
                  className="flex items-center space-x-1 dangerous-hover"
                />
              </div>
              <div className="flex items-center space-x-1">
                <Clock />
                <div
                  dangerouslySetInnerHTML={{ __html: contact.hours }}
                  className="flex items-center space-x-1 dangerous-hover"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const HeaderInner = ({ link, menu, navIsOpen, setNavIsOpen, isScrolled }) => {
  let parsed;

  if (typeof window !== `undefined`) {
    parsed = queryString.parse(location.search, { arrayFormat: 'comma' });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    parsed.search = e.target[1].value;
    const stringified = queryString.stringify(parsed, { arrayFormat: 'comma' });
    const newUrl = '/products' + '?' + stringified;
    history.pushState({}, null, newUrl);
    window.location.href = newUrl;
  };

  return (
    <div className="px-5 lg:px-10 py-2 lg:py-4 top-0 left-0 right-0 sticky">
      <div className="container container-full-proper ">
        <div className="flex items-center">
          <div className="flex items-center space-x-5 xl:space-x-[53px]">
            <MaybeLink link={{ url: '/' }} className="w-[135px] flex-1 fw-svg ">
              <Logo />
            </MaybeLink>
            <Menu menu={menu} isScrolled={isScrolled} />
          </div>
          <div className="ml-auto flex items-center space-x-5">
            <div className="flex items-center space-x-4">
              <div>
                <form action="" className="hidden md:block relative" onSubmit={handleSearch}>
                  <button className="absolute top-1/2 transform -translate-y-1/2 left-0 border-r-[#A3A3A3] px-3 pr-1.5 border-r box-content w-3 h-5 fw-svg">
                    <Search />
                  </button>
                  <input
                    type="text"
                    className="bg-black bg-opacity-5 rounded-[58px] outline-none t-14 py-1.5 p-1 pl-10 w-[206px]"
                    placeholder="Search hearing aids"
                  />
                </form>
              </div>
              <div className="flex items-center">
                <a
                  href="/my-account"
                  className="w-8 h-6 flex items-center justify-center hover:opacity-50 transition-opacity duration-200 ease-in-out"
                >
                  <div className="w-[15px] fw-svg">
                    <Person />
                  </div>
                </a>
                <div className="w-8 h-6 flex items-center justify-center ">
                  <CartIcon />
                </div>
              </div>
              <div className="hidden lg:block">
                <MaybeLink link={link}>
                  <Button link={link} buttonStyle="primary" size="header" />
                </MaybeLink>
              </div>
            </div>

            <div className="block xl:hidden">
              <button
                onClick={() => setNavIsOpen(!!!navIsOpen)}
                className={`flex items-center ${navIsOpen && 'active'}`}
              >
                <div className={`burger`}>
                  <span className="sr-only">Open menu</span>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
            </div>
          </div>
          <MobileNav menu={menu} navIsOpen={navIsOpen} link={link} />
        </div>
      </div>
    </div>
  );
};

export const Menu = ({ menu, isScrolled }) => {
  return (
    <nav className="hidden xl:block">
      <ul className="flex items-center space-x-5 xl:space-x-10">
        {menu.map((item, i) => (
          <li key={i} className="group">
            <MaybeLink
              link={item.link}
              className={`font-medium  tracking-[.001em] relative z-10 hover:opacity-50 duration-200 transition-opacity ease-in-out`}
            >
              <div className="flex items-center space-x-2">
                {item.icon && <MediaImage image={item.icon} className="w-8" />}
                <span>{item.link.title}</span>
              </div>
            </MaybeLink>
            {item.showSubmenu && <Submenu {...item.submenu} />}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export const Submenu = ({ items, brands }) => {
  return (
    <div className="absolute  top-0 pt-24 duration-500 opacity-0 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 transform -translate-x-6 transition-all ease-in-out group-hover:translate-x-0 ">
      <div className="bg-white rounded-[20px] overflow-hidden transform-gpu p-8 submenu-shadow grid grid-cols-2 gap-x-10">
        {items.map((item, i) => (
          <MaybeLink key={i} link={item.itemLink} className="space-y-5 group image-zoom">
            <div className="space-y-1">
              <div className="t-14">{item?.subheading}</div>
              <div className="t-21">{item?.itemLink?.title}</div>
            </div>
            <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-[10px] transform-gpu">
              <MediaImage image={item.image} className="!absolute " />
            </div>
          </MaybeLink>
        ))}
      </div>
    </div>
  );
};

export const MobileNav = ({ menu, navIsOpen, link }) => {
  const [activeMenu, setActiveMenu] = useState();

  return (
    <AnimatePresence>
      {navIsOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="bg-white px-5 text-navy-dark absolute inset-0 top-full block w-screen h-screen  pb-12 overflow-y-auto xl:hidden z-40 space-y-10"
        >
          <nav>
            <ul className="">
              {menu.map((item, i) => (
                <li
                  key={`menuLink${i}`}
                  className="leading-none"
                  onClick={
                    item.showSubmenu
                      ? (e) => (e.stopPropagation(), setActiveMenu(activeMenu === i ? null : i))
                      : undefined
                  }
                >
                  <Link
                    to={item.showSubmenu ? '#' : item.link.url}
                    className="flex items-center t-45 font-heading text-navy font-bold py-5 pt-6 border-t border-t-blue-grey"
                  >
                    {item.link.title}
                    {item.showSubmenu && (
                      <div
                        className={`w-8 h-8 rounded-full bg-navy transform rotate-180 text-white ml-auto flex justify-center items-center group-hover:bg-navy transition-all duration-300 ease-in-out ${
                          activeMenu === i && 'rotate-0'
                        }`}
                      >
                        <ArrowUp />
                      </div>
                    )}
                  </Link>
                  <AnimatePresence>{activeMenu === i && <MobileSubNav {...item.submenu} />}</AnimatePresence>
                </li>
              ))}
            </ul>
          </nav>
          <div className="flex flex-col button-fw">
            <MaybeLink link={link}>
              <Button link={link} buttonStyle="primary" />
            </MaybeLink>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const MobileSubNav = ({ items }) => {
  return (
    <div className="flex flex-col space-y-10">
      {items.map((item, i) => (
        <MaybeLink key={i} link={item.itemLink} className="space-y-4">
          <div className="">{item?.subheading}</div>
          <div className="t-21">{item?.itemLink?.title}</div>
          <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-[10px] transform-gpu">
            <MediaImage image={item.image} className="!absolute " />
          </div>
        </MaybeLink>
      ))}
    </div>
  );
};

export const HeaderFormFlow = ({ backgroundColour }) => {
  return (
    <header role="banner" className={`${backgroundColour} container-full-proper  absolute top-0 left-0 right-0 z-30`}>
      <div className="p-5 md:p-8 flex justify-center items-center relative">
        <Link
          to="/"
          className="mr-auto md:mr-0 md:absolute md:left-1/2 md:top-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:order-1"
        >
          <Logo />
        </Link>
        <Link
          to="/"
          onClick={() => navigate(-1)}
          className="flex items-center space-x-2 text-navy ml-auto mr-0 md:ml-0 md:mr-auto  hover:opacity-50 transition-opacity duration-200 ease-in-out"
        >
          <div className="w-1.5 fw-svg transform rotate-180">
            <ChevronRight />
          </div>
          <div className="font-semibold">Go back</div>
        </Link>
        <div className="hidden md:flex items-center space-x-1 text-navy ml-auto transition-opacity duration-300 ease-in-out hover:opacity-50">
          <div className="w-4 fw-svg">
            <Phone />
          </div>
          <div className="flex items-center">
            Call us:{' '}
            <a href="tel:877-220-6389" className="font-semibold ml-0.5 mktst_ph_qhzhaVIYmmKwpq2beA8w">
              877-220-6389
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export const Logo = () => {
  return (
    <svg width="135" height="31" viewBox="0 0 135 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.6581 7.15479L40.3905 15.9801V21.0926H37.621V15.9801L32.3232 7.15479H35.4287L39.0208 13.3441L42.6087 7.15479H45.6581Z"
        fill="currentColor"
      />
      <path
        d="M44.9515 19.9043C44.4405 19.407 44.0405 18.8071 43.7778 18.1443C43.515 17.4814 43.3954 16.7704 43.4268 16.058C43.405 15.3568 43.5237 14.6583 43.7759 14.0037C44.0282 13.3491 44.4088 12.7515 44.8955 12.2462C45.3893 11.7452 45.9823 11.3527 46.6364 11.0939C47.2905 10.8351 47.9915 10.7156 48.6944 10.7431C49.3962 10.7067 50.0979 10.8182 50.7538 11.0705C51.4098 11.3227 52.0053 11.7101 52.5019 12.2074C53.4367 13.2134 53.9366 14.5477 53.8931 15.9202C53.8991 16.2329 53.879 16.5455 53.8328 16.8548H46.0111C46.0737 17.4955 46.3825 18.087 46.8725 18.5045C47.4579 18.9545 48.1855 19.1792 48.9227 19.1376C50.0899 19.1376 50.9083 18.7069 51.3734 17.8067L53.7423 18.5045C53.3602 19.3265 52.7503 20.0215 51.985 20.5073C51.0626 21.1021 49.981 21.4026 48.8839 21.3687C48.164 21.3981 47.4455 21.2838 46.7703 21.0323C46.0951 20.7809 45.4768 20.3974 44.9515 19.9043ZM51.3261 15.2009C51.2722 14.586 50.9838 14.0153 50.5206 13.6073C50.0087 13.1662 49.348 12.9367 48.6729 12.9655C48.0054 12.944 47.3556 13.1817 46.8596 13.6288C46.3865 14.0272 46.0793 14.5878 45.9981 15.2009H51.3261Z"
        fill="currentColor"
      />
      <path
        d="M56.3696 20.4512C55.5496 19.9458 54.9289 19.173 54.6123 18.2632L56.9812 17.5741C57.1603 18.0707 57.4978 18.4945 57.9417 18.7801C58.4355 19.1009 59.0156 19.2632 59.6043 19.2453C60.0272 19.2655 60.4466 19.1606 60.8102 18.9437C60.9469 18.873 61.0621 18.7671 61.1439 18.6368C61.2257 18.5065 61.2711 18.3567 61.2754 18.2029C61.2754 17.7722 60.9093 17.4363 60.1599 17.2123L57.5756 16.4543C55.9131 15.9805 55.0904 15.076 55.0904 13.7623C55.0958 13.3337 55.2067 12.913 55.4133 12.5374C55.6199 12.1618 55.9158 11.8429 56.2748 11.6087C57.1046 11.0124 58.1093 10.7093 59.1305 10.7473C60.1053 10.727 61.0682 10.9647 61.9215 11.4365C62.6661 11.8564 63.253 12.5083 63.5927 13.2928L61.2582 13.9734C61.0505 13.5909 60.7368 13.2765 60.3548 13.0679C59.9728 12.8594 59.5387 12.7655 59.1046 12.7975C58.7476 12.7843 58.3949 12.8788 58.0925 13.0688C57.9713 13.1295 57.868 13.2205 57.7925 13.333C57.717 13.4454 57.672 13.5756 57.6617 13.7106C57.6617 14.081 57.9417 14.3438 58.4973 14.5074L61.0041 15.2267C61.7605 15.4203 62.4707 15.763 63.093 16.2346C63.3485 16.468 63.5475 16.7563 63.6751 17.0779C63.8027 17.3995 63.8555 17.7459 63.8295 18.0909C63.8341 18.5522 63.7258 19.0077 63.5141 19.4176C63.3024 19.8275 62.9937 20.1794 62.6149 20.4427C61.7035 21.0958 60.5996 21.4248 59.4793 21.3773C58.3729 21.391 57.2883 21.0681 56.3696 20.4512Z"
        fill="currentColor"
      />
      <path
        d="M77.8747 7.15479V21.0926H76.2811V14.7052H68.1622V21.0926H66.5771V7.15479H68.1622V13.2666H76.2854V7.15479H77.8747Z"
        fill="currentColor"
      />
      <path
        d="M80.6879 19.9169C80.1931 19.4194 79.8073 18.8243 79.5552 18.1695C79.3031 17.5147 79.1901 16.8145 79.2235 16.1137C79.2036 15.4327 79.3186 14.7544 79.5618 14.118C79.805 13.4815 80.1716 12.8995 80.6405 12.4052C81.1062 11.9133 81.6711 11.526 82.2978 11.2691C82.9246 11.0121 83.5988 10.8914 84.2758 10.9149C84.9487 10.8777 85.6218 10.9845 86.2501 11.2281C86.8785 11.4718 87.4476 11.8466 87.9196 12.3277C88.8207 13.2928 89.3051 14.5742 89.2677 15.894C89.2735 16.133 89.2606 16.3721 89.229 16.609H80.7396C80.7522 17.0697 80.8557 17.5234 81.0442 17.944C81.2326 18.3646 81.5024 18.7438 81.8379 19.0597C82.5558 19.7034 83.4979 20.0391 84.461 19.9944C85.1831 20.0164 85.8959 19.8262 86.5112 19.4474C87.0421 19.1117 87.4688 18.6343 87.743 18.0691L89.1514 18.4998C88.7654 19.327 88.1608 20.0329 87.4027 20.5414C86.5306 21.1129 85.503 21.4002 84.461 21.364C83.7668 21.3909 83.0745 21.2764 82.4259 21.0277C81.7774 20.7789 81.1861 20.401 80.6879 19.9169ZM87.743 15.4633C87.715 14.5944 87.3542 13.7694 86.7351 13.159C86.0597 12.561 85.1767 12.2517 84.2758 12.2976C83.8244 12.2833 83.3747 12.3585 82.9526 12.5189C82.5305 12.6793 82.1443 12.9217 81.8164 13.2322C81.1809 13.82 80.7954 14.6295 80.7396 15.4934L87.743 15.4633Z"
        fill="currentColor"
      />
      <path
        d="M91.7351 19.8569C91.2581 19.3603 90.8851 18.7734 90.638 18.1307C90.391 17.4879 90.2749 16.8023 90.2965 16.114C90.2761 15.4311 90.3929 14.751 90.64 14.114C90.8871 13.477 91.2595 12.8961 91.7351 12.4056C92.1924 11.9194 92.7472 11.5352 93.3633 11.2784C93.9794 11.0216 94.6428 10.8978 95.31 10.9153C96.0861 10.876 96.8587 11.0436 97.5488 11.4008C98.239 11.7579 98.8219 12.292 99.2381 12.9483V11.1565H100.763V21.0888H99.2381V19.2797C98.8306 19.9465 98.2504 20.4907 97.5589 20.8547C96.8674 21.2186 96.0903 21.3888 95.31 21.3472C94.6428 21.3646 93.9794 21.2408 93.3633 20.984C92.7472 20.7272 92.1924 20.3431 91.7351 19.8569ZM98.1958 18.8576C98.5535 18.5023 98.8347 18.0774 99.0221 17.6093C99.2095 17.1412 99.2992 16.6396 99.2855 16.1356C99.2982 15.6296 99.2073 15.1263 99.0185 14.6567C98.8296 14.1871 98.5467 13.7611 98.1871 13.4048C97.8439 13.0526 97.4322 12.7742 96.9774 12.5868C96.5226 12.3994 96.0344 12.3069 95.5426 12.3151C95.0478 12.2985 94.5549 12.3849 94.0952 12.5688C93.6355 12.7526 93.219 13.03 92.8722 13.3833C92.5211 13.748 92.2472 14.1796 92.0666 14.6525C91.8861 15.1253 91.8026 15.6297 91.8212 16.1356C91.8045 16.6379 91.8889 17.1385 92.0695 17.6076C92.25 18.0767 92.523 18.5047 92.8722 18.8663C93.2149 19.2264 93.6301 19.5098 94.0904 19.6977C94.5508 19.8855 95.0457 19.9736 95.5426 19.956C96.0349 19.9622 96.5233 19.8681 96.978 19.6792C97.4327 19.4904 97.8441 19.2108 98.1871 18.8576H98.1958Z"
        fill="currentColor"
      />
      <path
        d="M105.987 11.3545C106.662 11.0109 107.432 10.9018 108.175 11.0444V12.4701C107.056 12.3279 106.19 12.5691 105.561 13.2109C104.932 13.8527 104.609 14.9338 104.609 16.4542V21.0929H103.08V11.1564H104.609V12.8792C104.887 12.2324 105.372 11.6961 105.987 11.3545Z"
        fill="currentColor"
      />
      <path
        d="M109.864 7.71052C109.765 7.61138 109.687 7.49298 109.635 7.36262C109.584 7.23226 109.559 7.0927 109.563 6.95246C109.563 6.81779 109.591 6.68454 109.644 6.56087C109.697 6.4372 109.775 6.32567 109.873 6.23315C110.075 6.04195 110.344 5.93827 110.622 5.94461C110.901 5.93827 111.17 6.04195 111.372 6.23315C111.469 6.3261 111.545 6.43789 111.597 6.5616C111.649 6.68531 111.675 6.81833 111.673 6.95246C111.677 7.09191 111.653 7.23071 111.603 7.36083C111.553 7.49095 111.477 7.6098 111.381 7.71052C111.282 7.81062 111.164 7.88911 111.033 7.94102C110.903 7.99293 110.763 8.01709 110.622 8.012C110.482 8.01709 110.342 7.99293 110.212 7.94102C110.081 7.88911 109.963 7.81062 109.864 7.71052ZM109.864 11.1562H111.389V21.0885H109.864V11.1562Z"
        fill="currentColor"
      />
      <path
        d="M121.92 12.0736C122.673 12.9591 123.055 14.1007 122.988 15.2609V21.0927H121.455V15.4935C121.5 14.6652 121.244 13.8486 120.736 13.1935C120.471 12.9134 120.149 12.6949 119.791 12.5535C119.433 12.412 119.048 12.3511 118.664 12.3751C118.21 12.3574 117.758 12.4405 117.34 12.6185C116.922 12.7965 116.549 13.0649 116.248 13.4045C115.608 14.16 115.277 15.1292 115.322 16.118V21.1143H113.818V11.1562H115.343V12.9049C115.718 12.305 116.236 11.8076 116.851 11.4577C117.515 11.0865 118.265 10.8978 119.026 10.9107C119.563 10.8876 120.099 10.9793 120.598 11.1798C121.097 11.3804 121.548 11.6851 121.92 12.0736Z"
        fill="currentColor"
      />
      <path
        d="M134.996 11.1566V20.7485C135.015 21.3821 134.904 22.0128 134.668 22.6013C134.432 23.1897 134.078 23.7232 133.626 24.1684C133.158 24.623 132.603 24.9781 131.993 25.212C131.384 25.4459 130.734 25.5539 130.082 25.5294C127.902 25.5294 126.33 24.658 125.365 22.915L126.718 22.4197C127.429 23.5956 128.553 24.1856 130.099 24.1856C130.553 24.211 131.008 24.1375 131.431 23.9702C131.854 23.8029 132.236 23.5458 132.55 23.2165C133.156 22.5371 133.491 21.659 133.493 20.7485V19.0558C132.632 20.3307 131.329 20.9682 129.586 20.9682C128.921 20.9892 128.258 20.8714 127.64 20.6223C127.022 20.3731 126.463 19.9981 125.999 19.521C125.53 19.0518 125.161 18.4922 124.915 17.8761C124.67 17.2601 124.552 16.6005 124.569 15.9375C124.549 15.2721 124.665 14.6098 124.911 13.9913C125.157 13.3727 125.527 12.8111 125.999 12.341C126.466 11.869 127.026 11.4984 127.644 11.2524C128.261 11.0064 128.922 10.8902 129.586 10.9111C131.335 10.9111 132.637 11.5428 133.493 12.8062V11.1566H134.996ZM132.442 18.5217C132.791 18.1896 133.066 17.788 133.25 17.3429C133.434 16.8977 133.523 16.419 133.51 15.9375C133.52 15.4556 133.429 14.9769 133.244 14.532C133.058 14.087 132.782 13.6856 132.433 13.3532C132.087 13.0132 131.676 12.7459 131.225 12.5669C130.774 12.3879 130.291 12.3009 129.806 12.3109C129.315 12.2925 128.826 12.3734 128.367 12.5488C127.908 12.7242 127.489 12.9905 127.136 13.3317C126.79 13.6732 126.518 14.0823 126.337 14.5337C126.156 14.985 126.07 15.4687 126.085 15.9547C126.072 16.4347 126.159 16.9121 126.339 17.3569C126.52 17.8017 126.791 18.2042 127.136 18.539C127.489 18.8802 127.908 19.1465 128.367 19.3219C128.826 19.4973 129.315 19.5782 129.806 19.5598C130.292 19.5723 130.776 19.4873 131.229 19.3098C131.681 19.1322 132.094 18.8657 132.442 18.5261V18.5217Z"
        fill="currentColor"
      />
      <g clipPath="url(#clip0_1039_11063)">
        <g clipPath="url(#clip1_1039_11063)">
          <path
            d="M8.27148 30.9272L8.27148 27.0264C11.3315 27.0147 14.2629 25.7939 16.4267 23.6301C18.5905 21.4663 19.8113 18.5349 19.823 15.4748C19.823 12.4112 18.606 9.47301 16.4397 7.30668C14.2733 5.14034 11.3351 3.9233 8.27148 3.9233L8.27148 0.0224228C12.3761 0.0247784 16.3118 1.65637 19.2142 4.55874C22.1166 7.46112 23.7482 11.3969 23.7505 15.5015C23.7318 19.5961 22.0932 23.5169 19.1929 26.4072C16.2925 29.2976 12.3661 30.9226 8.27148 30.9272Z"
            fill="#4E86F5"
          />
          <path
            d="M2.74414 15.4482C2.74649 16.9228 3.33329 18.3363 4.37595 19.3789C5.41862 20.4216 6.83209 21.0084 8.30663 21.0107C9.78118 21.0084 11.1947 20.4216 12.2373 19.3789C13.28 18.3363 13.8668 16.9228 13.8692 15.4482C13.8668 13.9737 13.28 12.5602 12.2373 11.5175C11.1947 10.4749 9.78118 9.88807 8.30664 9.88572C6.83209 9.88807 5.41862 10.4749 4.37595 11.5175C3.33329 12.5602 2.74649 13.9737 2.74414 15.4482Z"
            fill="#4E86F5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1039_11063">
          <rect width="30.855" height="23.9314" fill="white" transform="translate(0 30.9272) rotate(-90)" />
        </clipPath>
        <clipPath id="clip1_1039_11063">
          <rect width="30.967" height="21.0149" fill="white" transform="translate(2.74414 30.9272) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
};

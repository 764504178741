import React, { useRef, useState, useEffect } from 'react';
import Image from '~/components/elements/Image';
import ReactPlayer from 'react-player';
import { PlayIcon } from '~/components/elements/Icon';

export const Media = (props) => {
  const { type } = props;
  if (type === 'image') {
    return <MediaImage {...props} />;
  }
  if (type === 'video') {
    return <MediaVideo {...props} />;
  }
};

export const MediaImage = ({
  image,
  className = 'relative',
  objectFit = 'contain',
  objectPosition = 'center',
  adjustment,
}) => {
  return (
    <Image
      image={image}
      className={className}
      objectFit={objectFit}
      objectPosition={objectPosition}
      {...getAdjustmentStyles(adjustment)}
    />
  );
};

export const MediaVideo = ({ video, videoPlaceholder }) => {
  return (
    <>
      {video !== null && video?.localFile?.publicURL && (
        <ReactPlayer
          url={video?.localFile?.publicURL}
          playing
          playIcon={<PlayIcon />}
          light={videoPlaceholder?.localFile?.publicURL}
        />
      )}
    </>
  );
};

export const getAdjustmentStyles = (adjustment) => {
  if (!adjustment) return {};
  const adjustBy = {};
  let adjusted = false;
  for (let key in adjustment) {
    if (key === 'fieldGroupName') continue;
    if (!adjustment[key]) continue;
    adjusted = true;
    adjustBy[key] = adjustment[key];
    if (key === 'width') {
      adjustBy.maxWidth = 'none';
    }
  }
  if (adjusted) {
    adjustBy.position = 'relative';
  }
  return adjusted ? { style: adjustBy } : {};
};

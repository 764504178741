import React, { useContext, useState } from 'react';
import { AppContext } from '~/components/context/AppContext';
import CartItem from '~/components/shop/cart/CartItem';
import { ThinCross } from '../../elements/Icon';
//import { isEmpty } from 'lodash';
//import Link from 'gatsby-link';
//import { getFormattedCart, getUpdatedItems } from '~/utils/functions';
//import UPDATE_CART from '~/mutations/update-cart';
//import GET_CART from '~/queries/get-cart';
//import CLEAR_CART_MUTATION from '~/mutations/clear-cart';
//import { useMutation, useQuery } from '@apollo/client';
//import { v4 } from 'uuid';

const CartDropDown = ({ isDropdownOpen, setIsDropdownOpen }) => {
  const [cart, setCart] = useContext(AppContext);
  const [requestError, setRequestError] = useState(null);
  const [fetchedCart, setFetchedCart] = useState(false);

  if (null === cart || !Object.keys(cart).length) {
    return null;
  }

  // // Get Cart Data.
  // const { data, refetch } = useQuery(GET_CART, {
  //   notifyOnNetworkStatusChange: true,
  //   onCompleted: () => {
  //     // console.warn( 'completed GET_CART', data );

  //     // Update cart in the localStorage.
  //     const updatedCart = getFormattedCart(data);
  //     localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart));

  //     // Update cart data in React Context.
  //     setCart(updatedCart);
  //     setFetchedCart(true)
  //   },
  // });

  // // Update Cart Mutation.
  // const [updateCart, { loading: updateCartProcessing }] = useMutation(
  //   UPDATE_CART,
  //   {
  //     onCompleted: () => {
  //       refetch();
  //     },
  //     onError: (error) => {
  //       if (error) {
  //         setRequestError(error.graphQLErrors[0].message);
  //       }
  //     },
  //   }
  // );

  // // Update Cart Mutation.
  // const [clearCart, { loading: clearCartProcessing }] = useMutation(
  //   CLEAR_CART_MUTATION,
  //   {
  //     onCompleted: () => {
  //       refetch();
  //     },
  //     onError: (error) => {
  //       if (error) {
  //         setRequestError(error.graphQLErrors[0].message);
  //       }
  //     },
  //   }
  // );

  // /*
  //  * Handle remove product click.
  //  *
  //  * @param {Object} event event
  //  * @param {Integer} Product Id.
  //  *
  //  * @return {void}
  //  */
  // const handleRemoveProductClick = (event, cartKey, products) => {
  //   event.stopPropagation();
  //   if (products.length) {
  //     // By passing the newQty to 0 in updateCart Mutation, it will remove the item.
  //     const newQty = 0;
  //     const updatedItems = getUpdatedItems(products, newQty, cartKey);

  //     updateCart({
  //       variables: {
  //         input: {
  //           clientMutationId: v4(),
  //           items: updatedItems,
  //         },
  //       },
  //     });
  //   }
  // };

  // // Clear the entire cart.
  // const handleClearCart = (event) => {
  //   event.stopPropagation();

  //   if (clearCartProcessing) {
  //     return;
  //   }

  //   clearCart({
  //     variables: {
  //       input: {
  //         clientMutationId: v4(),
  //         all: true,
  //       },
  //     },
  //   });
  // };

  return (
    <div
      className={`shopping-cart absolute top-full left-0 right-0 bg-white dropdown-cart  duration-500  transform  transition-all ease-in-out  ${
        isDropdownOpen
          ? 'translate-y-0 pointer-events-auto opacity-100'
          : 'opacity-0 pointer-events-none -translate-y-6'
      }`}
    >
      <div className="container-override py-10 pb-[60px]">
        <div className="relative space-y-10 lg:space-y-0 lg:grid grid-cols-12 lg:gap-x-8 xl:gap-x-16">
          <div className="absolute -top-5 -right-10">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              type="button"
              className="absolute top-2 right-2 w-7 h-7 bg-blue text-white rounded-full overflow-hidden flex items-center justify-center hover:bg-navy transition-colors duration-200 ease-in-out"
            >
              <div className="w-2.5 fw-svg">
                <ThinCross />
              </div>
            </button>
          </div>
          <div className="col-span-7">
            <div className="space-y-7">
              <h5 className="t-18 font-medium font-body pb-1.5">
                Item summary {cart.products.length && `(${cart.products.length})`}
              </h5>
              <div>
                {cart.products.length &&
                  cart.products.map((item) => (
                    <CartItem
                      key={item.productId}
                      item={item}
                      // updateCartProcessing={updateCartProcessing}
                      products={cart.products}
                      // handleRemoveProductClick={handleRemoveProductClick}
                      // updateCart={updateCart}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="col-span-5">
            <div className="space-y-7">
              <h5 className="t-18 font-medium font-body pb-1.5">Order summary</h5>
              <div className="border border-[#EEEEEE] rounded-[8px] p-6 space-y-5">
                <div className="flex items-center justify-between">
                  <div className="font-semibold">Total:</div>
                  <div className="font-bold">
                    {'string' !== typeof cart.totalProductsPrice
                      ? cart.totalProductsPrice.toFixed(2)
                      : cart.totalProductsPrice}
                  </div>
                </div>
                <p>Taxes and shipping calculated at next step</p>
              </div>
              <a
                href="/cart/"
                className="block"
                onClick={(e) => {
                  e.preventDefault();
                  window.location = '/cart/';
                }}
              >
                <button className="button button-primary w-full">
                  <span>View/Update Cart</span>
                  <span aria-hidden>View/Update Cart</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDropDown;

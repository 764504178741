import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ThinCross } from '~/components/elements/Icon';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputField from '~/components/elements/forms/InputField';
import TextareaField from '~/components/elements/forms/TextareaField';

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  phone: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
});

export function getCurrentSession() {
  let session = '';
  if (typeof window !== 'undefined') {
    session = localStorage.getItem('woo-session') || '';
  }
  return String(session);
}

export default function ModalContactForm({ open, setOpen, closeModal }) {
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      onClose={() => setOpen(false)}
      contentLabel="Example Modal"
      ariaHideApp={false}
      className="z-50 flex items-center justify-center h-screen"
    >
      <div className="bg-white shadow rounded-[15px] w-full max-w-[90%] md:max-w-[530px] mx-auto px-10 py-12 text-center relative overflow-scroll max-h-[85vh]">
        <button
          onClick={() => setOpen(false)}
          type="button"
          className="absolute top-2 right-2 w-7 h-7 bg-blue text-white rounded-full overflow-hidden flex items-center justify-center hover:bg-navy transition-colors duration-200 ease-in-out"
        >
          <div className="w-2.5 fw-svg">
            <ThinCross />
          </div>
        </button>
        <div className="space-y-10">
          <h2 className="t-42">How can we help</h2>
          <ModalFormInner />
        </div>
      </div>
    </Modal>
  );
}

export const ModalFormInner = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setIsSubmitted] = useState(false);

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        message: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setFieldValue }) => {
        const formData = new FormData();

        for (var key in values) {
          formData.append(key, values[key]);
        }

        fetch('https://hooks.zapier.com/hooks/catch/9806274/b84i1cp/', {
          method: 'POST',
          body: new URLSearchParams(formData as any),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
          .then((response) => {
            if (response.status === 200) {
              setIsSubmitted(true);
            }
          })
          .catch((error) => {
            setErrorMessage(error);
          });
        setErrorMessage(''); // reset error message
      }}
    >
      {(props) => {
        return (
          <Form className="">
            <div className="space-y-10">
              {errorMessage?.length > 0 && (
                <div
                  className="p-4 mt-4 border text-error border-error rounded-[4px]"
                  dangerouslySetInnerHTML={{ __html: errorMessage }}
                />
              )}

              {submitted && (
                <div className="p-4 mt-4 border text-green border-green rounded-[4px]">
                  Thanks for getting in touch, we'll get back to you soon.
                </div>
              )}
              <div
                className={`flex flex-col space-y-6 transition-opacity duration-300 ease-in-out ${
                  submitted && 'opacity-50 pointer-events-none'
                }`}
              >
                <InputField name="name" label="Your full name" />
                <InputField name="email" label="Your email address" />
                <InputField name="phone" label="Your phone number" />
                <TextareaField name="message" label="How can we help" />
                <input type="hidden" name="source" value="Rw8k051Gs71RmgcwIQSF" />
                <input type="hidden" name="session" value={getCurrentSession()} />
              </div>
              <div className="flex justify-center">
                <button type="submit" className="w-full button button-secondary ">
                  <span className="">Send message</span>
                  <span className="" aria-hidden>
                    Send message
                  </span>
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

import React, { useState, useEffect, createContext } from 'react';
import { useLocation } from '@reach/router';
import useScript from './useScript';

export const TaggingContext = createContext();

const getCampaign = () => {
  if (typeof window === 'undefined') return '1000002';
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('cid') || '1000002';
};

const getSourceId = () => {
  if (typeof window === 'undefined') return 'ImhDNXur7yTRu4iKML7W';
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('src') || 'ImhDNXur7yTRu4iKML7W';
};

export const TaggingProvider = ({ children }) => {
  const [session, setSession] = useState('');
  const [url] = useState('https://storage.googleapis.com/ys-private-public/yes-tagging-v3.js');
  const campaign = getCampaign();
  const source = getSourceId();
  const { pathname } = useLocation();
  const status = useScript(url);

  const initTagging = () => {
    if (typeof window !== 'undefined' && typeof window.initTagging === 'function') window.initTagging(campaign, source);
  };

  useEffect(() => {
    const initTagging = async (campaign, source) => {
      const data = await window.initTagging(campaign, source);
      setSession(data);
    };

    if (status === 'ready') {
      initTagging(campaign, source).catch((err) => console.error(err));
    }
  }, [status, pathname, campaign, source]);

  return <TaggingContext.Provider value={{ initTagging, session }}>{children}</TaggingContext.Provider>;
};

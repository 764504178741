import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { LinkInner } from '~/components/elements/Link';
import { MediaImage } from '~/components/elements/Media';
import { Star } from '~/components/elements/Icon';
import CustomCursor from "~/components/elements/CustomCursor";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, FreeMode } from "swiper";
import { ProductTag } from '../landing/Products';

import "swiper/css";
import { Link } from 'gatsby';

export const FeaturedProducts = (props) => {

	const { heading, feturedProductsLink, products } = props;

	return (
		<div className="container">
			<div className="space-y-12">
				<div className="flex flex-col space-y-5 md:flex-row md:space-y-0 md:items-center justify-between">
					<h2 className="t-50">{heading}</h2>
					<LinkInner {...feturedProductsLink} />
				</div>
				<CustomCursor >
					<Swiper
						modules={[A11y, FreeMode]}
						freeMode
						className={`w-full md:!mx-auto !overflow-visible z-10 relative`}
						slidesPerView={'auto'}
					>
						{products?.map((product, i) => (
							<React.Fragment key={`product-${i}`}>
								{product?.product?.featuredImage && (
									<SwiperSlide
										key={`productslide${i}`}
										className="!max-w-[306px] mr-5 md:mr-10 last:pr-0 box-content !h-auto"
									>
										<Link to={product?.product?.uri} className={`block card-shadow rounded-[20px] bg-white !text-navy p-8 !w-[306px] relative flex-shrink-0 transform  space-y-2 h-full`}>
											<div className="flex justify-between items-center h-[25px]">
												{product?.product?.postTypeProduct?.tag && <ProductTag productTag={product?.product?.postTypeProduct?.tag} /> }
												{product?.product?.postTypeProduct?.productVendor && <div className="text-navy text-[12px] font-bold px-2 py-1 rounded-[40px]">{product?.product?.postTypeProduct?.productVendor}</div>}
												{/* {product.logo && <MediaImage image={product.logo} className="w-[64px]" />} */}
											</div>
											<div className="space-y-2">
												<div className="h-[250px]">
													<MediaImage image={product?.product?.image} objectFit="contain" className="pointer-events-none w-full h-full" />
												</div>
												<div className="space-y-3">
													{/*<div className="flex items-center space-x-1 text-blue">
														{[...Array(4)].map((item, i) => (	
															<Star />
														))}
														</div>*/}
													<h5 className="t-20 !text-navy">{product?.product?.name}</h5>
												</div>
											</div>
										</Link>
									</SwiperSlide>
								)}
							</React.Fragment>
						))}

					</Swiper>
				</CustomCursor>
			</div>
		</div>
	);
};

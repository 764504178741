import React from 'react';
import { Media } from '~/components/elements/Media';
import { SmallStar, Quote } from '~/components/elements/Icon';

export const CustomerStories = (props) => {
  const { centredText, columnA, columnB, flipColumns } = props;
  return (
    <div className="container">
      <div
        className={`flex flex-col-reverse lg:flex-row items-center lg:justify-between ${
          flipColumns && 'lg:flex-row-reverse'
        }`}
      >
        <ColumnA {...columnA} flipColumns={flipColumns} centredText={centredText} />
        <ColumnB {...columnB} />
      </div>
    </div>
  );
};

export const ColumnA = ({ content, customerName, rating, flipColumns, centredText }) => {
  return (
    <div className={`w-full  mt-8 lg:mt-0 lg:w-2/5`}>
      <div className="max-w-[442px] max-w-[537px] max-w-[480px]" />
      <div
        className={`space-y-4 lg:space-y-10 max-w-[480px] ${centredText && 'text-center mx-auto'} ${
          flipColumns ? 'lg:ml-0' : 'lg:ml-auto'
        }`}
      >
        <Quote />
        <div>
          {content && (
            <p className={`t-18 t-black-65 max-w-[460px]  whitespace-pre-wrap ${centredText && 'mx-auto'}`}>
              {content}
            </p>
          )}
          {customerName && <p className="font-bold mt-4 text-black/70">{customerName}</p>}
        </div>
        {rating && (
          <div className="flex items-center gap-4">
            <p className="t-14 font-bold text-black/70 mt-1">Rated {rating}/5</p>
            <div className="flex">
              {[...Array(parseInt(rating))].map((item, i) => (
                <SmallStar key={`star-${i}`} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ColumnB = ({ image, type, video }) => {
  return (
    <div className="w-full lg:w-1/2">
      <Media image={image} type={type} vide={video} />
    </div>
  );
};

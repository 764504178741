import React from 'react';
import { Logo } from '~/components/global/Header';
import { Phone, Clock, ArrowUp, Facebook, Twitter, Instagram } from '~/components/elements/Icon';
import { MaybeLink, Button } from '~/components/elements/Link';
import { MediaImage } from '~/components/elements/Media';

export default function Footer({ data }) {
  const { optionsFooter } = data;

  return (
    <footer role="contentinfo" className="bg-navy text-white">
      <Contact {...optionsFooter} />
      <Inner {...optionsFooter} />
      <Info {...optionsFooter} />
    </footer>
  );
}

export const Contact = ({ contact }) => {
  return (
    <div className="bg-[#0C3B67]">
      <div className="container container-wide py-[52px] flex flex-col  lg:flex-row lg:items-center space-y-5 lg:space-y-0">
        <div className="flex flex-col items-center lg:items-start space-y-5 lg:space-y-0 lg:flex-row lg:space-x-5">
          <div className="block lg:hidden">
            <ScrollToTopButton />
          </div>
          <div className="lg:border-r border-r-white border-opacity-[.17] lg:pr-5 xl:pr-8 xxl:pr-8 block lg:hidden xl:block xl:!ml-0">
            <Logo />
          </div>
          <div className="flex items-center space-x-1.5 lg:!ml-0 xl:!ml-5">
            <Phone />
            <div
              dangerouslySetInnerHTML={{ __html: contact.call }}
              className="flex items-center space-x-1 dangerous-hover"
            />
          </div>
          <div className="flex items-center space-x-1.5">
            <Clock />
            <div
              dangerouslySetInnerHTML={{ __html: contact.hours }}
              className="flex items-center space-x-1 dangerous-hover"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center lg:space-x-5 xl:space-x-[70px] lg:ml-auto">
          <div
            className="trustpilot-widget w-[189px] sm:w-[420px] lg:-mr-4 xl:-mr-12"
            data-locale="en-US"
            data-template-id="5419b6ffb0d04a076446a9af"
            data-businessunit-id="5fc67a080737a300019b2dd4"
            data-style-height="20px"
            data-style-width="100%"
            data-theme="dark"
            data-text-color="#FFFFFF"
          >
            <a href="https://www.trustpilot.com/review/yeshearing.com" target="_blank" rel="noopener">
              <span className="hidden">Trustpilot</span>
            </a>
          </div>
          <div className="hidden lg:block">
            <ScrollToTopButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window && window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      className="w-[34px] h-[34px] rounded-full border border-white border-opacity-[.18] flex items-center justify-center hover:bg-white hover:text-navy transition-colors duration-200 ease-in-out"
      onClick={scrollToTop}
    >
      <div className="w-3 flex-none">
        <ArrowUp />
      </div>
    </button>
  );
};

export const Inner = ({ footer }) => {
  return (
    <div className="container container-wide md:pt-[77px] py-10 pb-5 md:pb-[113px] ">
      <div className="space-y-8">
        <div className="t-18 font-medium">Support</div>
        <div className="flex flex-col lg:flex-row items-start space-y-16 lg:space-y-0">
          <nav>
            <ul className="space-y-4">
              {footer.support.map((item, i) => (
                <li key={i}>
                  <MaybeLink
                    link={item.link}
                    className="opacity-100 transition-opacity duration-200 ease-in-out hover:opacity-60 space-x-4 flex items-center"
                  >
                    <MediaImage image={item.image} className="w-[60px]" />
                    <div>
                      <div className="t-18">{item.link.title}</div>
                      <div className="t-14 opacity-60">{item.content}</div>
                    </div>
                  </MaybeLink>
                </li>
              ))}
            </ul>
          </nav>
          <div className="w-full lg:w-auto lg:max-w-[60%] flex flex-wrap lg:flex-nowrap lg:ml-auto lg:space-x-10 xl:space-x-20">
            <div className="w-full flex flex-wrap lg:flex-nowrap lg:space-x-10 xl:space-x-20">
              {footer.menu.map((menu, i) => (
                <nav key={i} className="w-1/2 mb-8 lg:mb-0 lg:w-auto space-y-5">
                  <div className="t-18 font-medium">{menu.heading}</div>
                  <ul className="space-y-2">
                    {menu.items.map((item, q) => (
                      <li key={q}>
                        {item.link.title === 'Sign up' ? (
                          <MaybeLink
                            link={item.link}
                            triggerModal
                            modal="professionals"
                            className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100"
                          >
                            {item.link.title}
                          </MaybeLink>
                        ) : (
                          <MaybeLink
                            link={item.link}
                            className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100"
                          >
                            {item.link.title}
                          </MaybeLink>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
              ))}
            </div>

            {/*}
            <nav className="w-1/2 mb-8 lg:mb-0 lg:w-auto space-y-5">
                <div className="t-18 font-medium">Phase 2</div>
                <ul className="space-y-2">
                  <li><MaybeLink link={{url: '/customer-stories'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Customer Stories</MaybeLink></li>
                  <li><MaybeLink link={{url: '/audiogram'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Audiogram</MaybeLink></li>
                  <li><MaybeLink link={{url: '/home-service-plans'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Home Service Plans</MaybeLink></li>
                  <li><MaybeLink link={{url: '/resources'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Resources</MaybeLink></li>
                  <li><MaybeLink link={{url: '/category/blog'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Resources Category</MaybeLink></li>
                  <li><MaybeLink link={{url: '/lorem-ipsum-fusce-condimentum-orci-a-magna-7/'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Resources Article</MaybeLink></li>
                  <li><MaybeLink link={{url: '/affiliate-program'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Affiliate Program</MaybeLink></li>
                  <li><MaybeLink link={{url: '/refer-a-friend'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Refer a Friend</MaybeLink></li>
                </ul>
              </nav>
              <nav className="w-1/2 mb-8 lg:mb-0 lg:w-auto space-y-5">
                <div className="t-18 font-medium">Phase 2 cont...</div>
                <ul className="space-y-2">
                  <li><MaybeLink link={{url: '/careers'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Careers</MaybeLink></li>
                  <li><MaybeLink link={{url: '/contact'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Contact</MaybeLink></li>
                  <li><MaybeLink link={{url: '/faqs'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">FAQs</MaybeLink></li>
                  <li><MaybeLink link={{url: '/for-partners'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">For Partners</MaybeLink></li>
                  <li><MaybeLink link={{url: '/financing'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Financing</MaybeLink></li>
                  <li><MaybeLink link={{url: '/insurance'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Insurance</MaybeLink></li>
                  <li><MaybeLink link={{url: '/returns'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Returns</MaybeLink></li>
                  <li><MaybeLink link={{url: '/404'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">404</MaybeLink></li>
                </ul>
              </nav>
              <nav className="w-1/2 mb-8 lg:mb-0 lg:w-auto space-y-5">
                <div className="t-18 font-medium">Phase 2 cont...</div>
                <ul className="space-y-2">
                  <li><MaybeLink link={{url: '/find-your-product'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Find Your Product</MaybeLink></li>
                  <li><MaybeLink link={{url: '/product-hub'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Product Hub</MaybeLink></li>
                  <li><MaybeLink link={{url: '/products'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Products</MaybeLink></li>
                  <li><MaybeLink link={{url: '/product/audeo-paradise-90'}} className="opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100">Product</MaybeLink></li>
                </ul>
              </nav>
                      */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Info = ({ info }) => {
  return (
    <div className="border-t border-t-white border-opacity-[.13] ">
      <div className="container-full flex flex-col md:flex-row t-14 space-y-8 md:space-y-0 py-8 md:py-0">
        <div className="w-full md:w-[27%] text-center md:border-r border-r-white border-opacity-[.13] md:py-[75px] text-white text-opacity-60">
          © Yes Hearing {new Date().getFullYear()}.
        </div>
        <div className="flex-1 flex items-center justify-center">
          <ul className="flex items-center space-x-5">
            {info.legalMenu.map((item, i) => (
              <li key={i}>
                <MaybeLink
                  link={item.link}
                  className="text-white opacity-60 hover:opacity-100 transition-opacity duration-300 ease-in-out"
                >
                  <span dangerouslySetInnerHTML={{ __html: item.link.title }} />
                </MaybeLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-[27%] text-center md:border-l border-l-white border-opacity-[.13] md:py-[75px]">
          <ul className="flex items-center justify-center space-x-10">
            {info.socialMenu.map((item, i) => (
              <li key={i}>
                <MaybeLink
                  link={item.link}
                  className="text-white opacity-60 hover:opacity-100 transition-opacity duration-300 ease-in-out"
                >
                  {item.icon === 'facebook' ? (
                    <Facebook />
                  ) : item.icon === 'instagram' ? (
                    <Instagram />
                  ) : item.icon === 'twitter' ? (
                    <Twitter />
                  ) : (
                    ''
                  )}
                </MaybeLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { LinkInner } from '~/components/elements/Link';
import { Tick } from '~/components/elements/Icon';

export const Plans	 = (props) => {
	const { heading, content, plansLink, plans, ticks } = props;
	return (
		<div className="container space-y-10 lg:space-y-[105px]">
		
			<div className="text-center space-y-7 ">
				<h2 className="t-65 max-w-[867px] mx-auto">{heading}</h2>
				<p className="t-18 t-black-65 max-w-[615px] mx-auto">{content}</p>
				{plansLink && <LinkInner {...plansLink} />}
				{ticks && (
					<div className={`flex justify-center items-center  flex-col space-x-0 space-y-4 flex-wrap md:flex-row md:space-y-0 md:space-x-6`}>
						{ticks.map((tick, i) => (
							<div className={`flex items-center space-x-3 `}>
								<div className="text-green">
									<Tick />
								</div>
								<div className="font-medium ">
									{tick.content}
								</div>
							</div>
						))}
					</div>
				)}
			</div>
			<div className="max-w-[395px] mx-auto lg:max-w-none lg:mx-0 grid gap-y-10 lg:gap-y-0 lg:grid-cols-3 lg:gap-x-[46px]">
				{plans.map((plan, i) => (
					<Plan key={i} {...plan} />
				))}
			</div>
		</div>
	);
};


export const Plan = ({plan, features, price, planLink}) => {
	return (
		<div className="bg-white text-navy card-shadow p-5 py-8 lg:p-8 space-y-6 flex flex-col rounded-[20px]">
			<div className="border-b border-b-black border-opacity-10 pb-6 space-y-4">
				{plan?.stars && (
					<div className="flex">
						{[...Array(parseInt(plan?.stars))].map((item, i) => (
							<StaticImage key={i} src="../../assets/images/star.png" alt="" className="w-[60px]" />
						))}
					</div>
				)}
				<div className="space-y-2">
					{plan?.heading && (<h5 className="t-32 !text-navy">{plan?.heading}</h5>)}
					<p>{plan?.summary}</p>
				</div>
			</div>
			<div className="border-b border-b-black border-opacity-10 pb-6">
				<div className="space-y-1">
					<h5 className="t-28 !text-navy">{price?.perMonth}</h5>
					<p>{price?.savings}</p>
				</div>
			</div>
			<div className=" pb-6">
				<div className="space-y-5">
					{features?.heading && (<div className="t-18 font-semibold text-navy">{features.heading}</div>)}
					<ul className="space-y-3">
						{features?.features.map((feature, i) => (
							<li key={i} className="flex items-center space-x-3">
								<div className="text-green">
									<Tick />
								</div>
								<div className="font-medium text-black text-opacity-60">
									{feature.text}
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className="!mt-auto button-fw">
				{planLink && <LinkInner {...planLink} />}
			</div>
		</div>
	)
}


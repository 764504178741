import React, { useContext, useState } from 'react';
import CartDropDown from './CartDropdown';
import { AppContext } from '~/components/context/AppContext';
import { Person, Cart, Phone, Clock, ArrowUp, ChevronRight, Search } from '~/components/elements/Icon';

const CartIcon = () => {
  const [cart] = useContext(AppContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const productsCount = null !== cart && Object.keys(cart).length ? cart.totalProductsCount : '';
  const totalPrice = null !== cart && Object.keys(cart).length ? cart.totalProductsPrice : '';

  return (
    <>
      <button className="woo-menu-cart-icon group" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <div className="woo-next-cart-wrap">
          <div className="woo-next-cart-icon-container relative">
            <div className="w-[17px] fw-svg group-hover:opacity-50 transition-opacity duration-200 ease-in-out">
              <Cart />
            </div>
            {productsCount ? (
              <span className="woo-next-cart-count bg-blue text-white w-[18px] h-[18px] rounded-full absolute top-[-9px] left-[-9px] text-[10px] font-medium">
                {productsCount}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </button>
      <CartDropDown isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />
    </>
  );
};

export default CartIcon;

import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from 'react';

const useProductAttributes = () => {
  return useStaticQuery(graphql`
	query PDQuery {
    allWcProductsAttributes {
      nodes {
        name
        slug
        attribute_options {
          id
          menu_order
          name
          slug
        }
      }
    }
  }`).allWcProductsAttributes
};

export default useProductAttributes;

import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaImage } from '~/components/elements/Media';
import { LinkInner } from '~/components/elements/Link';

export const ThreeColumnBlocks = (props) => {
	const { heading, content, link, blocks, layout, headingSize, headingWidth } = props;

	const containerClasses = ['container ']
	const headingClasses = ['mx-auto']
	const contentClasses = ['mx-auto t-black-65 t-18']

	if(layout === '2') {
		headingClasses.push('max-w-[385px] ')
		containerClasses.push('space-y-10 lg:space-y-16')
		contentClasses.push('max-w-[587px]')
	} else {
		containerClasses.push('space-y-10 lg:space-y-[105px]')
		contentClasses.push('max-w-[643px]')

		if(headingSize === '75'){
			headingClasses.push('max-w-[853px] ')
		} else {
			headingClasses.push('max-w-[766px] ')
		}
		
	}

	if(headingSize === '45') {
		headingClasses.push('t-45 ')
	} else if(headingSize === '75') {
		headingClasses.push('t-75 ')
	} else {
		headingClasses.push('t-59 ')
	}

	if(headingWidth === '385') {
		headingClasses.push('!max-w-[385px]')
	}

	return (
		<>
		<div className={containerClasses.join(' ')}>
			<div className="text-center space-y-4 ">
				<h2 className={headingClasses.join(' ')}>{heading}</h2>
				<p className={contentClasses.join(' ')}>{content}</p>
			</div>
			<div className="max-w-[360px] mx-auto lg:max-w-none lg:mx-0 grid gap-y-10 lg:gap-y-0 lg:grid-cols-3 lg:gap-x-[69px]">
				{blocks.map((block, i) => (
					<div key={i} className="bg-white py-8 px-5 xl:px-8 space-y-4 text-center card-shadow rounded-[20px]">
						<MediaImage image={block.image} className="w-[120px] h-[120px] mx-auto" objectFit="contain" objectPosition="center"  />
						<h5 className="t-21">{block.heading}</h5>
						{block.content && <p>{block.content}</p>}
						{block?.blockLink && <LinkInner {...block.blockLink} />}
					</div>
				))}
			</div>
			<div className="flex justify-center !mt-16">
				{link && <LinkInner {...link} />}
			</div>
		</div>
		<div className="max-w-[587px] max-w-[643px] max-w-[766px] max-w-[385px]"></div>
		</>
	);
};

import React from 'react';
import { MediaImage } from '~/components/elements/Media';
import { MaybeLink, LinkInner, Button } from '~/components/elements/Link';
import { Star } from '~/components/elements/Icon';
import useProductAttributes from '~/hooks/useProductAttributes';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const ShopBy = (props) => {
  const { heading, type, features, manufacturers, levels } = props;
  const { allWcProductsAttributes } = useProductAttributes();

  return (
    <div className="container">
      <div className="space-y-12">
        <h3 className="t-28">{heading}</h3>
        {type === 'manufacturer' ? (
          <Manufacturers items={manufacturers} attributes={allWcProductsAttributes} />
        ) : type === 'level' ? (
          <Levels items={levels} attributes={allWcProductsAttributes} />
        ) : (
          type === 'feature' && <Features items={features} attributes={allWcProductsAttributes} />
        )}
      </div>
    </div>
  );
};

export const Manufacturers = ({ items, attributes }) => {
  //const { allWcProductsAttributes } = useProductAttributes();

  const imageClasses = ['w-full h-full w-[130px] h-[70px] mx-auto'];

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-10">
      {items?.map((item, i) => {
        return (
          <MaybeLink
            key={i}
            link={{ url: item?.link?.url }}
            className="bg-white flex justify-center items-center p-5 card-shadow rounded-[12px] border border-black border-opacity-[.15]"
          >
            <MediaImage
              image={item.image}
              alt={item?.image?.altText}
              className={imageClasses.join(' ')}
              objectFit="contain"
            />
          </MaybeLink>
        );
      })}
    </div>
  );
};

export const Levels = ({ items, attributes }) => {
  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5 sm:gap-6">
      {items?.map((item, i) => {
        return (
          <MaybeLink
            key={i}
            link={{ url: item?.link?.url }}
            className="block rounded-[6px] border border-black border-opacity-[.15]"
          >
            <div className="aspect-w-3 aspect-h-2">
              <MediaImage
                image={item.image}
                alt={item?.image?.altText}
                className="!absolute w-full h-full"
                objectFit="cover"
              />
            </div>
            <div className="p-7">
              <div className="space-y-5 pb-5 border-b border-black border-opacity-[.15]">
                <div className="flex items-blue text-blue space-x-1">
                  {[...Array(parseInt(item.stars))].map((star, i) => (
                    <div key={i} className="fw-svg w-6">
                      <Star />
                    </div>
                  ))}
                </div>
                <h4 className="t-32">{item.heading}</h4>
              </div>
              <div className="space-y-5 pt-5">
                {/* todo: Connect to the CMS */}
                <h6 className="text-green t-15 font-heading">
                  from <span className="!text-[23px]">{item.fromPrice}</span>
                </h6>
                <p className="t-black-65">{item.summary} </p>
                <div className="fw-button">
                  <Button link={item?.link} buttonStyle="outline-small" />
                </div>
              </div>
            </div>
          </MaybeLink>
        );
      })}
    </div>
  );
};

export const Features = ({ items, attributes }) => {
  const imageClasses = ['w-full h-full w-[130px] h-[70px] mx-auto'];
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10">
      {items?.map((item, i) => {
        return (
          <MaybeLink
            key={i}
            link={{ url: item?.link?.url }}
            className="text-center flex flex-col justify-center items-center p-5 py-6 card-shadow rounded-[12px] border border-black border-opacity-[.15] space-y-5"
          >
            <MediaImage
              image={item.image}
              alt={item?.image?.altText}
              className={imageClasses.join(' ')}
              objectFit="contain"
            />
            <div className="t-28 text-navy">{item?.link?.title}</div>
          </MaybeLink>
        );
      })}
    </div>
  );
};

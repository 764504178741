import React from 'react';

import { Cta } from '~/components/flexible/Cta';
import { Careers } from '~/components/flexible/Careers';
import { Customers } from '~/components/flexible/Customers';
import { CustomerStories } from '../flexible/CustomerStories';
import { Faqs } from '~/components/flexible/Faqs';
import { Features } from '~/components/flexible/Features';
import { FeaturedProducts } from '~/components/flexible/FeaturedProducts';
import { FeaturedResources } from '~/components/flexible/FeaturedResources';
import { FeaturedTeam } from '~/components/flexible/FeaturedTeam';
import { Grid } from '~/components/flexible/Grid';
import { HowItWorks } from '~/components/flexible/HowItWorks';
import { Image } from '~/components/flexible/Image';
import { InsurancePlans } from '~/components/flexible/InsurancePlans';
import { InteractiveMap } from '~/components/flexible/InteractiveMap';
import { Logos } from '~/components/flexible/Logos';
import { Masthead } from '~/components/flexible/Masthead';
import { Mission } from '~/components/flexible/Mission';
import { Plans } from '~/components/flexible/Plans';
import { Program } from '~/components/flexible/Program';
import { Reviews } from '~/components/flexible/Reviews';
import { Rewards } from '~/components/flexible/Rewards';
import { Services } from '~/components/flexible/Services';
import { ShopBy } from '~/components/flexible/ShopBy';
import { Steps } from '~/components/flexible/Steps';
import { Support } from '~/components/flexible/Support';
import { TeamMemberFaqs } from '~/components/flexible/TeamMemberFaqs';
import { Testimonial } from '~/components/flexible/Testimonial';
import { Testimonials } from '~/components/flexible/Testimonials';
import { TextContent } from '~/components/flexible/TextContent';
import { TermsConditions } from '~/components/flexible/TermsConditions';
import { ThreeColumnBlocks } from '~/components/flexible/ThreeColumnBlocks';
import { TwoColumns } from '~/components/flexible/TwoColumns';
import { TwoColumnsCalculator } from '~/components/flexible/TwoColumnsCalculator';
import { TwoColumnLogos } from '~/components/flexible/TwoColumnLogos';
import { TwoColumnSlider } from '~/components/flexible/TwoColumnSlider';
import { Usps } from '~/components/flexible/Usps';
import { Values } from '~/components/flexible/Values';
import { Video } from '~/components/flexible/Video';

export const ContentRenderer = ({ prefix, content = [] }) => {
  return (
    <>
      {content.map((layout, i) => {
        const layoutName = layout?.fieldGroupName.replace(prefix, '');

        if (layoutName === 'Cta') {
          return <Cta key={i} {...layout} />;
        } else if (layoutName === 'Careers') {
          return <Careers key={i} {...layout} />;
        } else if (layoutName === 'Customers') {
          return <Customers key={i} {...layout} />;
        } else if (layoutName === 'CustomerStories') {
          return <CustomerStories key={i} {...layout} />;
        } else if (layoutName === 'Faqs') {
          return <Faqs key={i} {...layout} />;
        } else if (layoutName === 'Features') {
          return <Features key={i} {...layout} />;
        } else if (layoutName === 'FeaturedProducts') {
          return <FeaturedProducts key={i} {...layout} />;
        } else if (layoutName === 'FeaturedResources') {
          return <FeaturedResources key={i} {...layout} />;
        } else if (layoutName === 'FeaturedTeam') {
          return <FeaturedTeam key={i} {...layout} />;
        } else if (layoutName === 'Grid') {
          return <Grid key={i} {...layout} />;
        } else if (layoutName === 'HowItWorks') {
          return <HowItWorks key={i} {...layout} />;
        } else if (layoutName === 'Image') {
          return <Image key={i} {...layout} />;
        } else if (layoutName === 'InsurancePlans') {
          return <InsurancePlans key={i} {...layout} />;
        } else if (layoutName === 'InteractiveMap') {
          return <InteractiveMap key={i} {...layout} />;
        } else if (layoutName === 'Logos') {
          return <Logos key={i} {...layout} />;
        } else if (layoutName === 'Masthead') {
          return <Masthead key={i} {...layout} />;
        } else if (layoutName === 'Mission') {
          return <Mission key={i} {...layout} />;
        } else if (layoutName === 'Plans') {
          return <Plans key={i} {...layout} />;
        } else if (layoutName === 'Program') {
          return <Program key={i} {...layout} />;
        } else if (layoutName === 'Reviews') {
          return <Reviews key={i} {...layout} />;
        } else if (layoutName === 'Rewards') {
          return <Rewards key={i} {...layout} />;
        } else if (layoutName === 'Services') {
          return <Services key={i} {...layout} />;
        } else if (layoutName === 'ShopBy') {
          return <ShopBy key={i} {...layout} />;
        } else if (layoutName === 'Steps') {
          return <Steps key={i} {...layout} />;
        } else if (layoutName === 'Support') {
          return <Support key={i} {...layout} />;
        } else if (layoutName === 'TeamMemberFaqs') {
          return <TeamMemberFaqs key={i} {...layout} />;
        } else if (layoutName === 'Testimonial') {
          return <Testimonial key={i} {...layout} />;
        } else if (layoutName === 'Testimonials') {
          return <Testimonials key={i} {...layout} />;
        } else if (layoutName === 'TextContent') {
          return <TextContent key={i} {...layout} />;
        } else if (layoutName === 'TermsConditions') {
          return <TermsConditions key={i} {...layout} />;
        } else if (layoutName === 'ThreeColumnBlocks') {
          return <ThreeColumnBlocks key={i} {...layout} />;
        } else if (layoutName === 'TwoColumns') {
          return <TwoColumns key={i} {...layout} />;
        } else if (layoutName === 'TwoColumnsCalculator') {
          return <TwoColumnsCalculator key={i} {...layout} />;
        } else if (layoutName === 'TwoColumnLogos') {
          return <TwoColumnLogos key={i} {...layout} />;
        } else if (layoutName === 'TwoColumnSlider') {
          return <TwoColumnSlider key={i} {...layout} />;
        } else if (layoutName === 'Usps') {
          return <Usps key={i} {...layout} />;
        } else if (layoutName === 'Values') {
          return <Values key={i} {...layout} />;
        } else if (layoutName === 'Video') {
          return <Video key={i} {...layout} />;
        } else {
          console.log('IMPLEMENT ', layoutName, layout);
        }
        return null;
      })}
    </>
  );
};

import React from 'react';
import { MediaImage } from '~/components/elements/Media';

export const Features = (props) => {
  const { blocks, heading } = props;
  return (
    <div className="container">
      {heading && <h2 className="t-50 text-center mb-20">{heading}</h2>}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-7">
        {blocks?.map((block, i) => (
          <React.Fragment key={i}> 
              <div className="bg-[#ECF3FF] flex flex-col md:col-span-1 rounded-[10px] overflow-hidden">
                <MediaImage image={block.image} className="w-full h-[250px] mx-auto" objectFit="cover" />
                <div className="space-y-3 py-10 px-5 md:py-12 md:px-8">
                  <h5 className="t-32">{block.heading}</h5>
                  <p className="t-18 t-black-65">{block.content}</p>
                </div>
              </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

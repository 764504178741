import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import Autocomplete from 'react-autocomplete';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, Search, Star } from '~/components/elements/Icon';
import { MaybeLink, LinkInner } from '~/components/elements/Link';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { Manufacturers } from '~/components/flexible/ShopBy';
import queryString, { parse } from 'query-string';
import useProductAttributes from '~/hooks/useProductAttributes';
import Image from '~/components/elements/Image';
import attrs from 'gatsby-plugin-image/dist/src/babel-plugin-parse-static-images';
import { isArray } from '@apollo/client/cache/inmemory/helpers';
import { Button } from '../elements/Link';
import { array, number } from 'yup/lib/locale';

export const Products = (props) => {
  const { allWpProduct } = props;
  const data = useStaticQuery(graphql`
    query MyQuery {
      allWpProduct {
        nodes {
          id
          name
          sku
          slug
          featured
          postTypeProduct {
            productVendor
            productDescription
          }
          reviews {
            averageRating
          }
          attributes {
            nodes {
              id
              ... on WpGlobalProductAttribute {
                slug
              }
              options
            }
          }
          ... on WpSimpleProduct {
            id
            name
            price
            postTypeProduct {
              showPrice
              tag
            }
            description
            featured
            featuredImage {
              node {
                ...Image
              }
            }
            regularPrice
            salePrice
          }
          ... on WpVariableProduct {
            id
            name
            price
            postTypeProduct {
              showPrice
              tag
            }
            description
            featured
            featuredImage {
              node {
                ...Image
              }
            }
            regularPrice
            salePrice
          }
          productCategories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
      allWcProductsCategories {
        nodes {
          name
          slug
        }
      }
      allWcProductsAttributes {
        nodes {
          name
          slug
          attribute_options {
            id
            menu_order
            name
            slug
          }
        }
      }
    }
  `);
  return (
    <>
      <ProductsInner
        allWpProduct={data?.allWpProduct?.nodes}
        allAttributes={data?.allWcProductsAttributes?.nodes}
        allCategories={data?.allWcProductsCategories?.nodes}
        selectProducts={props.selectProducts || null}
      />
    </>
  );
};

export const SelectProducts = ({ selectProducts }) => {
  return <Products selectProducts={selectProducts} />;
};

export const ProductsInner = ({ allWpProduct, allCategories, allAttributes, selectProducts }) => {
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [keyword, setKeyword] = useState('');
  const [isInit, setIsInit] = useState(false);

  const productSectionRef = useRef(null);

  useEffect(() => {
    let parsed;

    if (typeof window !== `undefined`) {
      parsed = queryString.parse(location.search, { arrayFormat: 'comma' });
    }

    //Check if it's parsed as string. If yes, convert it to array
    Object.keys(parsed).forEach((parsedAttr) => {
      if (parsedAttr !== 'search') {
        parsed[parsedAttr] = !isArray(parsed[parsedAttr]) ? [parsed[parsedAttr]] : parsed[parsedAttr];
      }
    });

    if (parsed.search) setKeyword(parsed.search);

    let attrParse = parsed;
    delete attrParse.search;
    setSelectedAttribute({ ...attrParse });
  }, []);

  useLayoutEffect(() => {
    let parsed;

    if (typeof window !== `undefined`) {
      parsed = queryString.parse(location.search, { arrayFormat: 'comma' });
    }

    parsed = { ...selectedAttribute, search: keyword };

    if (isInit) {
      const stringified = queryString.stringify(parsed, { arrayFormat: 'comma' });
      const newUrl = window.location.pathname + '?' + stringified;

      history.pushState({}, null, newUrl);
    }

    setIsInit(true);
  }, [selectedAttribute, isInit]);

  return (
    <section
      ref={productSectionRef}
      className="section section-bot bg-blue-light border-t border-t-black border-opacity-10"
      id="#products"
    >
      <div className="container">
        <div className="space-y-10 lg:space-y-0 lg:grid grid-cols-12 gap-x-10">
          <div className="col-span-3">
            <div className="relative h-full">
              <div className="bg-white card-shadow p-5 py-2 md:py-8 md:p-8 rounded-[10px] sticky top-28">
                <Filter
                  attributes={allAttributes}
                  selectedAttribute={selectedAttribute}
                  setSelectedAttribute={setSelectedAttribute}
                  productSectionRef={productSectionRef}
                  attributesFilter={selectProducts?.attributesFilter}
                />
              </div>
            </div>
          </div>
          <div className="col-span-9">
            <div className="space-y-10">
              <Brands />
              <Callback />
              <ProductsSearch keyword={keyword} setKeyword={setKeyword} allWpProduct={allWpProduct} />
              <div className="space-y-12">
                {selectProducts?.selectProducts && !keyword && Object.keys(selectedAttribute).length === 0 ? (
                  <>
                    {selectProducts?.selectProducts?.map((products, key) => {
                      let product_list = [];
                      products?.products?.forEach((product) => {
                        product_list.push(product.product);
                      });
                      return (
                        <ProductsList
                          label={products.heading}
                          key={key}
                          selectedAttribute={selectedAttribute}
                          keyword={keyword}
                          productList={product_list}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    {allCategories.map((category, key) => {
                      const product_list = allWpProduct.filter((product) => {
                        return product.productCategories.nodes[0].slug === category.slug;
                      });
                      if (category.name === 'Accessory') {
                        return undefined;
                      } else {
                        return product_list.length ? (
                          <ProductsList
                            label={category.name}
                            key={key}
                            selectedAttribute={selectedAttribute}
                            keyword={keyword}
                            productList={product_list}
                          />
                        ) : (
                          ''
                        );
                      }
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const ProductsSearch = ({ keyword, setKeyword, allWpProduct }) => {
  const [autocompleteValue, setAutocompleteValue] = useState('');

  const allSearchProducts = [
    ...allWpProduct.filter((product) => !product?.productCategories?.nodes?.some((e) => e.name === 'Accessory')),
    { id: 'Phnoak', name: 'Phonak' },
    { id: 'Signia', name: 'Signia' },
    { id: 'Starkey', name: 'Starkey' },
    { id: 'ResSound', name: 'ReSound' },
    { id: 'Oticon', name: 'Oticon' },
  ];
  let parsed;

  if (typeof window !== `undefined`) parsed = queryString.parse(location.search, { arrayFormat: 'comma' });

  const handleSearch = (value) => {
    parsed.search = value;
    const stringified = queryString.stringify(parsed, { arrayFormat: 'comma' });
    const newUrl = '/products' + '?' + stringified;
    history.pushState({}, null, newUrl);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setKeyword(event.target.value);
      handleSearch(event.target.value);
    }
  };

  return (
    <div className="hidden md:block relative">
      <button className="absolute top-[18px] left-6 w-4 fw-svg">
        <Search />
      </button>
      <Autocomplete
        wrapperStyle={{ width: '100%' }}
        items={allSearchProducts.sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })}
        shouldItemRender={(item, value) => item?.name?.toLowerCase().indexOf(value?.toLowerCase()) > -1}
        getItemValue={(item) => item.name}
        renderItem={(item, highlighted) => (
          <div key={item?.id} style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}>
            {item?.name}
          </div>
        )}
        renderMenu={(items, value, styles) => (
          <div key={items} className={`open-search-field ${value === '' ? 'hidden' : ''}`} style={{ ...styles }}>
            {items}
          </div>
        )}
        renderInput={(props) => (
          <input
            id="triageInputId"
            onKeyDown={handleKeyDown}
            placeholder="Search for a product"
            className="form-input outline-none !pl-16"
            {...props}
          />
        )}
        value={autocompleteValue}
        onChange={(e) => {
          setAutocompleteValue(e.target.value);
          if (e.target.value === '') setKeyword('');
        }}
        onSelect={(value) => {
          setAutocompleteValue(value);
          setKeyword(value);
          handleSearch(value);
        }}
      />
    </div>
  );
};

export const Brands = () => {
  return <Manufacturers />;
};

export const Callback = () => {
  return (
    <div className="bg-blue text-white px-10 py-12 rounded-[10px] relative space-y-8">
      <div className="space-y-4">
        <h3 className="t-28">Need help choosing?</h3>
        <p className="t-18 text-white text-opacity-[.65]">
          Call us today on{' '}
          <a className="underline mktst_ph_qhzhaVIYmmKwpq2beA8w" href="tel:818-659-8505">
            (818) 659-8505
          </a>{' '}
          to speak with an expert
        </p>
      </div>
      <LinkInner link={{ url: '/book-a-consultation', title: 'Request a callback' }} />
      <StaticImage
        src="../../assets/images/call.png"
        alt=""
        className="w-[113px] object-contain !absolute top-6 right-6"
      />
    </div>
  );
};

export const ProductsList = ({ label, productList, selectedAttribute, keyword }) => {
  //Filter product list according to the search results
  const filtered_pd_list = productList.filter((product) => {
    return (
      (!Object.keys(selectedAttribute).length || isAttributeMatches(product?.attributes?.nodes, selectedAttribute)) &&
      (!keyword || product?.name?.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
    );
  });

  const feat_product = filtered_pd_list.filter((e) => e?.featured)[0];

  return (
    <>
      {!!filtered_pd_list.length && (
        <div className="space-y-4">
          <h2 className="t-32">{label}</h2>
          <div className="bg-white card-shadow rounded-[10px] px-5 p-10 md:px-10 space-y-10">
            {feat_product ? (
              <div>
                <FeaturedProduct
                  title={feat_product?.name}
                  slug={feat_product?.slug}
                  image={feat_product?.featuredImage?.node}
                  price={feat_product?.price}
                  showPrice={feat_product?.postTypeProduct?.showPrice}
                  ratings={feat_product?.reviews?.averageRating}
                  attributes={feat_product?.attributes?.nodes}
                  selectedAttribute={selectedAttribute}
                  keyword={keyword}
                  description={feat_product?.postTypeProduct?.productDescription}
                  productTag={feat_product?.postTypeProduct?.tag}
                />
              </div>
            ) : (
              ''
            )}
            <div>
              <div className="space-y-10 sm:space-y-0 sm:grid sm:gap-x-5 grid-cols-3 md:gap-x-10 gap-y-8">
                {filtered_pd_list.map((product, index) => (
                  <SingleProduct
                    title={product?.name}
                    key={index}
                    slug={product?.slug}
                    ratings={product?.reviews?.averageRating}
                    attributes={product?.attributes?.nodes}
                    selectedAttribute={selectedAttribute}
                    keyword={keyword}
                    image={product?.featuredImage?.node}
                    price={product?.price}
                    showPrice={product?.postTypeProduct?.showPrice}
                    productTag={product?.postTypeProduct?.tag}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const isAttributeMatches = (attributes, selectedAttribute) => {
  const attributesToSearch = Object.values(selectedAttribute).flat();
  let relAttribute = [];

  attributes.forEach((element) => {
    relAttribute.push(element?.options);
  });

  relAttribute = relAttribute.flat();

  return attributesToSearch.every((i) => relAttribute.includes(i));
};

export const FeaturedProduct = ({
  title,
  ratings,
  image,
  slug,
  price,
  showPrice,
  description,
  attributes,
  selectedAttribute,
  keyword,
  productTag,
}) => {
  const show =
    (!Object.keys(selectedAttribute).length || isAttributeMatches(attributes, selectedAttribute)) &&
    (!keyword || title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);
  return (
    <>
      {!!show && (
        <div className={Object.keys(selectedAttribute).length && !show ? 'hidden' : 'block'}>
          <Link to={`/product/${slug}`} className="block">
            <div className="space-y-5 md:space-y-0 block md:grid grid-cols-3 items-center pb-10 border-b border-b-black border-opacity-10">
              <div className="col-span-1">
                <div className="aspect-w-1 aspect-h-1 h-[270px]">
                  <Image className="!absolute" image={image} />
                </div>
              </div>
              <div className="col-span-2">
                <div className="space-y-5 max-w-[475px]">
                  <div className="flex space-x-2 items-center">
                    {productTag && <ProductTag productTag={productTag} />}
                    <div className="flex items-center space-x-1 text-blue">
                      {[...Array(ratings)].map((item, i) => (
                        <Star key={i} />
                      ))}
                    </div>
                  </div>
                  <h5 className="t-36">{title}</h5>
                  <p className="t-black-65 max-w-[366px]" dangerouslySetInnerHTML={{ __html: description }} />
                  <div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:justify-between sm:items-end">
                    {showPrice && <Price amount={price} />}
                    <div className="w-full sm:w-auto">
                      <Button
                        link={{ url: '/product/audeo-paradise-90', title: 'View product' }}
                        buttonStyle="outline"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export const SingleProduct = ({
  title,
  image,
  price,
  showPrice,
  slug,
  ratings,
  attributes,
  selectedAttribute,
  keyword,
  productTag,
}) => {
  const show =
    (!Object.keys(selectedAttribute).length || isAttributeMatches(attributes, selectedAttribute)) &&
    (!keyword || title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);

  return (
    <>
      {!!show && (
        <Link to={`/product/${slug}`} className={Object.keys(selectedAttribute).length && !show ? 'hidden' : 'show'}>
          <div className="h-full space-y-2">
            <div className="flex flex-col justify-between h-full">
              <div className="flex flex-col">
                <div className="flex items-center space-x-2">
                  {productTag && <ProductTag productTag={productTag} />}
                  <div className="flex items-center space-x-1 text-blue">
                    {[...Array(ratings)].map((item, i) => (
                      <Star key={i} />
                    ))}
                  </div>
                </div>
                <h5 className="t-20 h-[65px]">{title}</h5>
                <div className="w-full h-[250px] flex">
                  <Image className="!absolute" image={image} className="w-full max-h-[200px]" />
                </div>
              </div>
              <div>
                <div className="space-y-2">
                  {showPrice ? (
                    <Price amount={price} />
                  ) : (
                    <>
                      <div className="italic t-12px text-center">Call for Pricing</div>
                    </>
                  )}
                </div>
                <div className="fw-button !mt-6">
                  <Button link={{ url: `/product/${slug}`, title: 'View product' }} buttonStyle="outline" />
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export const Price = ({ amount }) => {
  let splitPrice = amount?.split('-');
  let finalSplitPrice = 0;

  if (splitPrice?.length > 1) {
    finalSplitPrice = splitPrice[1].trim().replace('$', '').replace(/,/g, '');
    parseFloat(finalSplitPrice);
  }
  return (
    amount && (
      <div className="">
        <div className="italic">Starting from</div>
        <div className="t-23-bold text-navy">{amount}</div>
        {finalSplitPrice !== 0 && (
          <div className="t-14 text-blue">Or ${Math.ceil(finalSplitPrice / 6)}/month for 6 months</div>
        )}
      </div>
    )
  );
};

export const Filter = ({
  attributes,
  selectedAttribute,
  setSelectedAttribute,
  productSectionRef,
  attributesFilter,
}) => {
  const [expanded, setExpanded] = useState([]);
  let showAttributes = [];

  const allWcProductsAttributes = useProductAttributes();

  attributesFilter.forEach((attribute) => {
    allWcProductsAttributes?.nodes?.filter((product) => {
      if (attribute?.attribute === product?.name) {
        showAttributes.push(product);
      }
    });
  });

  return (
    <div>
      {showAttributes && showAttributes.length > 0 ? (
        <>
          {showAttributes?.map((node, i) => (
            <SingleFilterItem
              key={i}
              node={node}
              i={i}
              expanded={expanded}
              setExpanded={setExpanded}
              selectedAttribute={selectedAttribute}
              setSelectedAttribute={setSelectedAttribute}
              productSectionRef={productSectionRef}
            />
          ))}
        </>
      ) : (
        <>
          {allWcProductsAttributes?.nodes?.map((node, i) => (
            <SingleFilterItem
              key={i}
              node={node}
              i={i}
              expanded={expanded}
              setExpanded={setExpanded}
              selectedAttribute={selectedAttribute}
              setSelectedAttribute={setSelectedAttribute}
              productSectionRef={productSectionRef}
            />
          ))}
        </>
      )}
    </div>
  );
};

export const SingleFilterItem = ({
  node,
  i,
  expanded,
  setExpanded,
  selectedAttribute,
  setSelectedAttribute,
  productSectionRef,
}) => {
  function toggleExpanded(expanded, i) {
    const value = !expanded[i];
    setExpanded({ ...expanded, [i]: value });
  }

  return (
    <div className="border-t border-t-black border-opacity-5 py-4  first:border-t-0" key={`faq${i}`}>
      <header className="relative cursor-pointer group pr-5" onClick={() => toggleExpanded(expanded, i)}>
        <div className={`font-semibold text-navy`}>{node.name}</div>
        <div
          className={`text-navy  absolute top-1.5 right-0 transition-all duration-200 fw-svg w-1.5 h-auto ease-in-out ${
            expanded[i] === true ? '-rotate-90 ' : 'rotate-90'
          } `}
        >
          <ChevronRight />
        </div>
      </header>
      <AnimatePresence>
        {expanded[i] === true && (
          <motion.div
            className="overflow-hidden"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3 }}
          >
            <div className="py-2 pt-4 space-y-2">
              {node?.attribute_options?.map((option, i) => (
                <FilterItem
                  type={node?.slug}
                  label={option?.name}
                  slug={option?.slug}
                  key={i}
                  selectedAttribute={selectedAttribute}
                  setSelectedAttribute={setSelectedAttribute}
                  productSectionRef={productSectionRef}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const FilterItem = ({ type, label, slug, selectedAttribute, setSelectedAttribute, productSectionRef }) => {
  const inputRef = useRef(null);

  const updateFilter = (e) => {
    let attr = selectedAttribute;
    if (e.target.checked) {
      if (!attr[type]?.length) {
        attr[type] = [slug];
        setSelectedAttribute({ ...attr });
      } else {
        attr[type].push(slug);
        setSelectedAttribute({ ...attr });
      }
    } else {
      // when unchecking the last options of type, remove the whole array from object
      if (attr[type]?.length === 1) {
        delete attr[type];
        setSelectedAttribute({ ...attr });
      } else {
        attr[type] = attr[type].filter((brand) => brand !== slug);
        setSelectedAttribute({ ...attr });
      }
    }
    productSectionRef.current.scrollIntoView();
  };

  useLayoutEffect(() => {
    let attr = selectedAttribute[type]?.length ? selectedAttribute[type] : [];

    if (attr?.length && attr.includes(slug)) {
      inputRef.current.checked = true;
    }
  }, [selectedAttribute]);

  return (
    <label className={`flex !text-black items-start flex-auto relative space-y-1 group `}>
      <input
        ref={inputRef}
        type="checkbox"
        style={{ flex: '0 0 18px' }}
        onChange={(e) => updateFilter(e)}
        className={`checkbox-green cursor-pointer mt-2 rounded-[2px] w-[18px] h-[18px] border border-[#ABABAB] group-hover:border-navy focus-within:border-navy bg-transparent appearance-none outline-none group-hover:border-opacity-100 focus:border-opacity-100 checked:border-opacity-100 transition-colors duration-200 ease-in-out `}
      />
      <div className={`t-15 text-left cursor-pointer pl-2.5`} dangerouslySetInnerHTML={{ __html: label }} />
    </label>
  );
};

export const ProductTag = ({ productTag }) => {
  return <div className="bg-blue text-white text-[12px] font-bold px-2 py-1 rounded-[40px]">{productTag}</div>;
};

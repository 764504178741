import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaImage } from '~/components/elements/Media';

export const Image = (props) => {
	const { image, layout } = props;
	const containerClasses = []

	if(layout === 'default') {
		containerClasses.push('container')
	} else {
		containerClasses.push('container-full')
	}

	return (
		<div className={containerClasses.join(' ')}>
			<MediaImage className="rounded-xl overflow-hidden" image={image} />
		</div>
	);
};

import React, { useState } from 'react';
import { v4 } from "uuid";
import { getUpdatedItems } from "~/utils/functions";
import isEmpty from "validator/es/lib/isEmpty";
import { Bin, Spinner } from '~/components/elements/Icon';

const CartItem = ({
	item,
	products,
	updateCartProcessing,
	handleRemoveProductClick,
	updateCart,
}) => {

	const [productCount, setProductCount] = useState(item.qty);

	/*
	 * When user changes the qty from product input update the cart in localStorage
	 * Also update the cart in global context
	 *
	 * @param {Object} event event
	 *
	 * @return {void}
	 */
	const handleQtyChange = (event, cartKey, type) => {

		if (typeof window !== 'undefined') {

			event.stopPropagation();
			let newQty;

			// If the previous update cart mutation request is still processing, then return.
			if (updateCartProcessing || ('decrement' === type && 1 === productCount)) {
				return;
			}

			if (!isEmpty(type)) {
				newQty = 'increment' === type ? productCount + 1 : productCount - 1;
			} else {
				// If the user tries to delete the count of product, set that to 1 by default ( This will not allow him to reduce it less than zero )
				newQty = (event.target.value) ? parseInt(event.target.value) : 1;
			}

			// Set the new qty in state.
			setProductCount(newQty);

			if (products.length) {

				const updatedItems = getUpdatedItems(products, newQty, cartKey);

				updateCart({
					variables: {
						input: {
							clientMutationId: v4(),
							items: updatedItems
						}
					},
				});
			}

		}
	};

	return (
		<div className="py-5 border-t-[#EEEEEE] last:border-b-[#EEEEEE] last:border-b border-t flex flex-col sm:flex-row sm:items-center space-y-5 sm:space-y-0">
			<div className="space-y-5 sm:space-y-0 sm:space-x-10 flex flex-col sm:flex-row  sm:items-center sm:w-1/2">
				<div className="w-full sm:w-20 sm:h-20  rounded-[4px]">
					<img src={item.image.sourceUrl} className="w-full h-full object-contain object-center" srcSet={item.image.srcSet} />
				</div>
				<div className="space-y-2">
					<div className="t-18 font-bold">{item.name}</div>
				</div>
			</div>
			<div className=" sm:w-1/2 sm:flex flex-col sm:flex-row space-y-5 md:space-y-0 sm:items-center sm:justify-between">
				<div className="inline-flex sm:flex items-center bg-blue text-white px-4 rounded-[60px] relative" >
					{updateCart ? (
						<>
							<button className="increment-btn text-[20px]" onClick={(event) => handleQtyChange(event, item.cartKey, 'decrement')} >-</button>
							<input
								type="number"
								min="1"
								data-cart-key={item.cartKey}
								className={`appearance-none input-number outline-none font-semibold woo-next-cart-qty-input bg-blue text-center px-1 w-8 form-control block ${updateCartProcessing ? 'woo-next-cart-disabled' : ''} `}
								value={productCount}
								onChange={(event) => handleQtyChange(event, item.cartKey, '')}
							/>
							<button className="decrement-btn text-[20px]" onClick={(event) => handleQtyChange(event, item.cartKey, 'increment')}>+</button>
						</>
					) : (
						<span className="font-semibold text-center block">{productCount}</span>
					)}
					{updateCartProcessing ? (
						<div className="w-4 fw-svg absolute -right-6 top-1/2 transform -translate-y-1/2 text-blue">
							<Spinner />
						</div>
					) : ''}
				</div>
				<div className="font-semibold">
					{/*${('string' !== typeof item.price) ? item.price.toFixed(2) : item.price}*/}
					{item?.totalPrice}
				</div>
				{handleRemoveProductClick && (
					<button className="cart-remove-item font-semibold text-[12px] flex space-x-3" onClick={(event) => handleRemoveProductClick(event, item.cartKey, products)}>
						<Bin />
						<div>Remove</div>
					</button>
				)}
			</div>
		</div>
	)
};

export default CartItem;

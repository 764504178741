import { graphql, useStaticQuery } from "gatsby";

const useBlogPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          date
          dateGmt
          title
          nodeType
          slug
          uri
          id
          excerpt
          featuredImage{
            node {
              altText
              caption
              id
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
          postTypePost {
            previewSummary
            previewImage {
              ... Image
            }
            previewVideo { 
              sourceUrl
            }
            authorDetails {
              fullName
              jobTitle
              image {
                ... Image
              }
            }
          }
          categories {
            nodes {
              id
            }
          }
        }
      }
    }
  `);

  return data;
};

export default useBlogPosts;

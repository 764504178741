import React, { useEffect, useContext } from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaImage } from '~/components/elements/Media';
import { LinkInner } from '~/components/elements/Link';
import { Tick, Squiggle } from '~/components/elements/Icon';
import { StaticImage } from 'gatsby-plugin-image';
import ReactPlayer from 'react-player';
import ForPartnersForm from '~/components/elements/ForPartnersForm';
import AffiliateProgramForm from '~/components/elements/AffiliateProgramForm';
import AudiogramForm from '~/components/elements/AudiogramForm';
import { Phone } from '~/components/elements/Icon';

import { TaggingContext } from '../context/TaggingContext';

export const Masthead = (props) => {
  const { layout } = props;
  return (
    <>
      {layout === 'home' ? (
        <MastheadHome {...props} />
      ) : layout === 'our-experts' ? (
        <MastheadOurExperts {...props} />
      ) : layout === 'how-it-works' ? (
        <MastheadHowItWorks {...props} />
      ) : layout === 'our-mission' ? (
        <MastheadOurMission {...props} />
      ) : layout === 'for-professionals' ? (
        <MastheadForProfessionals {...props} />
      ) : layout === 'for-partners' ? (
        <MastheadForPartners {...props} />
      ) : layout === 'affiliate-program' ? (
        <MastheadAffiliateProgram {...props} />
      ) : layout === 'audiogram' ? (
        <MastheadAudiogram {...props} />
      ) : layout === 'products' ? (
        <MastheadProducts {...props} />
      ) : layout === 'refer-a-friend' ? (
        <MastheadRefer {...props} />
      ) : (
        <MastheadDefault {...props} />
      )}
    </>
  );
};

export const MastheadHome = ({ subheading, heading, content, image, video, links, mastheadLogos }) => {
  return (
    <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 pt-5 lg:pt-0 mx-auto">
      <div className="container-inner flex-1 flex flex-col justify-center py-5 lg:py-10 lg:w-[56%] lg:max-w-[810px] lg:ml-auto">
        <div className="space-y-7">
          <h1 className="t-70 max-w-[548px] text-navy leading-none">{heading}</h1>
          <div className="t-20 max-w-[443px] t-black-65" dangerouslySetInnerHTML={{ __html: content }} />
          {links.map((link, i) => (
            <div key={i} className="masthead-home-button">
              <LinkInner {...link} />
            </div>
          ))}
        </div>
        <div className="mt-10 lg:mt-[75px]">
          <div
            className="trustpilot-widget w-[189px] sm:w-[420px] sm:-ml-2.5"
            data-locale="en-US"
            data-template-id="5419b6ffb0d04a076446a9af"
            data-businessunit-id="5fc67a080737a300019b2dd4"
            data-style-height="20px"
            data-style-width="100%"
            data-theme="light"
            data-text-color="#0B3F6F"
          >
            <a href="https://www.trustpilot.com/review/yeshearing.com" target="_blank" rel="noopener">
              <span className="hidden">Trustpilot</span>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-[44%] relative">
        {video ? (
          <ReactPlayer
            url={video?.localFile?.publicURL}
            playing={true}
            muted={true}
            width={'100%'}
            height={'100%'}
            loop
            playsinline
            className="masthead-home-video"
          />
        ) : (
          image && <MediaImage image={image} objectFit="cover" className="w-full h-full masthead-home-image" />
        )}

        <div className="absolute left-0 bottom-0 pb-5  md:pb-[60px] right-0 px-5 space-y-3 md:space-y-6 masthead-home-logos">
          {mastheadLogos.heading && <h6 className="text-white text-center">{mastheadLogos.heading}</h6>}
          <div className="flex items-center justify-center space-x-8">
            {mastheadLogos.images.map((logo, i) => (
              <MediaImage
                key={i}
                image={logo.image}
                className="w-[55px] h-[10px] md:w-[110px] md:h-[20px]"
                objectFit="contain"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MastheadDefault = ({ subheading, heading, content, image, links, ticks, layout }) => {
  return (
    <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-10 lg:space-y-0 container mx-auto  pt-5 py-10 lg:pt-10 lg:py-20">
      <div className="w-full  lg:w-[50%] xl:w-[46%] flex flex-col justify-center">
        <Content
          subheading={subheading}
          heading={heading}
          content={content}
          links={links}
          ticks={ticks}
          layout={layout}
        />
      </div>
      <div className="w-full lg:w-[48%] xl:w-[54%] lg:ml-auto relative">
        <MediaImage image={image} />
      </div>
    </div>
  );
};

export const MastheadOurExperts = ({ subheading, heading, content, image, links, ticks, layout }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center lg:space-y-0 pt-5 py-10 lg:pt-10 lg:py-20 container mx-auto relative">
      <div className="lg:w-[64%] flex flex-col justify-center  mr-auto relative z-[1]">
        <div className="lg:pb-[115px] lg:pt-[90px] max-w-[760px]">
          <Content
            subheading={subheading}
            heading={heading}
            content={content}
            links={links}
            ticks={ticks}
            layout={layout}
          />
        </div>
      </div>
      <div className="w-full lg:w-[49%] relative lg:absolute right-[2%]">
        <MediaImage image={image} />
      </div>
    </div>
  );
};

export const MastheadHowItWorks = ({ subheading, heading, content, image, links, ticks, layout }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center lg:pt-[92px] container mx-auto relative">
      <div className="lg:w-[60%] flex flex-col justify-center  mr-auto relative z-[1]">
        <div className="py-10 lg:pb-[195px] lg:pt-[60px] max-w-[760px]">
          <Content
            subheading={subheading}
            heading={heading}
            content={content}
            links={links}
            ticks={ticks}
            layout={layout}
          />
        </div>
      </div>
      <div className="w-full lg:w-[42%] relative lg:absolute lg:right-[2%] lg:mt-[-10%]">
        <MediaImage image={image} />
      </div>
    </div>
  );
};

export const MastheadForProfessionals = ({ subheading, heading, content, image, links, ticks, layout }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center space-y-10 lg:space-y-0 container mx-auto relative pt-5 py-10 lg:pt-10 lg:py-20">
      <div className="lg:w-[55%] flex-1 flex flex-col justify-center  mr-auto">
        <div className="lg:py-20 max-w-[760px]">
          <Content
            subheading={subheading}
            heading={heading}
            content={content}
            links={links}
            ticks={ticks}
            layout={layout}
          />
        </div>
      </div>
      <div className="w-full lg:w-[43.75%] lg:absolute lg:right-[3%]">
        <MediaImage image={image} />
      </div>
    </div>
  );
};

export const MastheadRefer = ({ subheading, heading, content, image, links, ticks, layout }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center space-y-10 lg:space-y-0 container mx-auto relative pt-5 py-10 lg:pt-10 lg:py-20">
      <div className="lg:w-[52%] flex flex-col justify-center  mr-auto">
        <div className="lg:py-20 max-w-[760px]">
          <Content
            subheading={subheading}
            heading={heading}
            content={content}
            links={links}
            ticks={ticks}
            layout={layout}
          />
        </div>
      </div>
      <div className="w-full lg:w-[30%] lg:absolute lg:right-[10%]">
        <MediaImage image={image} />
      </div>
    </div>
  );
};

export const MastheadForPartners = ({ subheading, heading, content, image, links, ticks, layout }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center space-y-10 lg:space-y-0 container mx-auto relative pt-5 py-10 lg:pt-10 lg:py-20">
      <div className="lg:w-[55%] flex-1 flex flex-col justify-center  mr-auto">
        <div className="lg:py-20 max-w-[760px]">
          <Content
            subheading={subheading}
            heading={heading}
            content={content}
            links={links}
            ticks={ticks}
            layout={layout}
          />
        </div>
      </div>
      <div className="w-full lg:w-[43.75%] ">
        <ForPartnersForm />
      </div>
    </div>
  );
};

export const MastheadAffiliateProgram = ({ subheading, heading, content, image, links, ticks, layout }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center space-y-10 lg:space-y-0 container mx-auto relative pt-5 py-10 lg:pt-10 lg:py-20">
      <div className="w-full lg:w-[55%] flex-1 flex flex-col justify-center  mr-auto">
        <div className="lg:py-20 max-w-[760px] space-y-10">
          <div className="w-full fw-svg max-w-[397px]">
            <Squiggle />
          </div>
          <Content
            subheading={subheading}
            heading={heading}
            content={content}
            links={links}
            ticks={ticks}
            layout={layout}
            ticksVertical
          />
        </div>
      </div>
      <div className="w-full lg:w-[43.75%] relative z-10">
        <AffiliateProgramForm />
      </div>
      <div className="hidden lg:block absolute bottom-[-5%] right-[-5%] w-[55%]">
        <MediaImage image={image} />
      </div>
    </div>
  );
};

export const MastheadAudiogram = ({ subheading, heading, content, image, links, ticks, layout }) => {
  return (
    <div className="overflow-hidden lg:min-h-[calc(100vh-143px)]">
      <div className=" flex flex-col xxl:flex-row items-center space-y-10 xxl:space-y-0 container mx-auto relative pt-5 py-10 xxl:pt-10 xxl:py-20">
        <div className="w-full xxl:w-[55%] flex-1 flex flex-col justify-center  mr-auto">
          <div className="xxl:py-20 max-w-[760px] space-y-10">
            <div className="w-full fw-svg max-w-[397px]">
              <Squiggle />
            </div>
            <Content
              subheading={subheading}
              heading={heading}
              content={content}
              links={links}
              ticks={ticks}
              layout={layout}
              ticksVertical
            />
          </div>
        </div>
        <div className="w-full xxl:w-[43.75%] relative z-10">
          <AudiogramForm />
        </div>
        <div className="hidden lg:block absolute bottom-[-5%] right-[-5%] w-[55%]">
          <MediaImage image={image} />
        </div>
      </div>
    </div>
  );
};

export const MastheadOurMission = ({ subheading, heading, ourMission, image }) => {
  return (
    <div className="container text-center space-y-10 md:space-y-20 pt-10 lg:pt-20 pb-5 md:pb-20  relative overflow-hidden">
      <StaticImage
        src="../../assets/images/masthead-illustration-mission-1.png"
        className="w-[55%] !absolute -left-10 bottom-48"
        alt=""
      />
      <StaticImage
        src="../../assets/images/masthead-illustration-mission-2.png"
        className="w-[43%] !absolute -right-20 top-24"
        alt=""
      />
      <div className="space-y-7 relative !mt-0">
        <div className="t-20-normal font-semibold text-blue">{subheading}</div>
        <h1 className="t-36 max-w-[869px] mx-auto">{heading}</h1>
      </div>
      <div className=" max-w-[994px] mx-auto relative">
        <div className="grid grid-cols-2 gap-x-6">
          {ourMission?.teamMembers?.map((member, i) => (
            <div key={i} className="text-center space-y-8">
              <MediaImage image={member?.image} />
              <div className="space-y-1">
                <h6 className="t-24">{member?.name}</h6>
                <div className="t-14">{member?.role}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const MastheadProducts = ({ subheading, heading, content, links, ourMission, image, imageLeft, imageRight }) => {
  const { initTagging } = useContext(TaggingContext);

  useEffect(() => {
    initTagging('HyTjMvBsFsQjODfiJgYh');
  }, [initTagging]);

  return (
    <div className="container text-center space-y-10 md:space-y-20 pt-10 lg:pt-20 pb-40 md:pb-20  relative overflow-hidden">
      <MediaImage image={imageLeft} className="w-[30%] !absolute left-0 bottom-10" alt="" />
      <MediaImage image={imageRight} className="w-[26%] !absolute right-0 bottom-8" alt="" />
      <div className="space-y-7 relative !mt-0">
        <h1 className="t-75 max-w-[750px] mx-auto">{heading}</h1>
        {content && (
          <div className="t-18 max-w-[586px] mx-auto t-black-65" dangerouslySetInnerHTML={{ __html: content }} />
        )}
        <div className="flex flex-col space-y-5 sm:space-y-0 sm:flex-row items-center justify-center sm:space-x-8">
          {links.map((link, i) => (
            <div key={i} className="masthead-home-button">
              <LinkInner {...link} />
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <a
            href="tel:877-220-6389"
            className="flex items-center space-x-2 text-navy font-medium underline mktst_ph_qhzhaVIYmmKwpq2beA8w"
          >
            (877) 220-6389
          </a>
        </div>
      </div>
      <div className=" max-w-[994px] mx-auto relative">
        <div className="grid grid-cols-2 gap-x-6">
          {ourMission?.teamMembers?.map((member, i) => (
            <div key={i} className="text-center space-y-8">
              <MediaImage image={member?.image} />
              <div className="space-y-1">
                <h6 className="t-24">{member?.name}</h6>
                <div className="t-14">{member?.role}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const Content = ({ subheading, heading, content, links, ticks, layout, ticksVertical = false }) => {
  const headingClasses = [''];
  const contentClasses = ['t-18 t-black-65'];

  if (layout === 'our-experts') {
    headingClasses.push('t-70');
    contentClasses.push('max-w-[556px]');
  } else if (layout === 'for-professionals') {
    headingClasses.push('t-75');
    contentClasses.push('max-w-[477px]');
  } else if (layout === 'how-it-works') {
    headingClasses.push('t-65');
    contentClasses.push('max-w-[477px]');
  } else {
    headingClasses.push('t-75');
    contentClasses.push('max-w-[485px]');
  }

  return (
    <div className="space-y-6 ">
      <div className="space-y-6">
        {subheading && (
          <h6 className="font-body font-semibold t-14-upper tracking-[.16em] text-green masthead-subheading">
            {subheading}
          </h6>
        )}
        <h1 className={headingClasses.join(' ')}>{heading}</h1>
        <div className={contentClasses.join(' ')} dangerouslySetInnerHTML={{ __html: content }} />
        {ticks && (
          <div
            className={`flex flex-col ${
              ticksVertical ? '' : 'md:flex-row md:space-y-0 md:space-x-6'
            } space-x-0 space-y-4 flex-wrap `}
          >
            {ticks.map((tick, i) => (
              <div key={i} className={`flex items-center space-x-3 `}>
                <div className="text-green">
                  <Tick />
                </div>
                <div className="font-medium masthead-tick" dangerouslySetInnerHTML={{ __html: tick.text }} />
              </div>
            ))}
          </div>
        )}
      </div>

      {links && (
        <div className="flex flex-col md:flex-row space-y-2.5 md:space-y-0 flex-wrap md:space-x-5 ">
          {links.map((link, i) => (
            <div key={i} className="masthead-button">
              <LinkInner {...link} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
